import React from "react";
import "./Careers.css";
import { Container, Row, Col, Tab, Tabs, Card } from "react-bootstrap";
import NavBar from "../../compounds/AppBar/AppBar";
import Footer from "../../compounds/Footer/Footer";
import Button from "../../compounds/Button/Button";
import { useNavigate } from "react-router-dom";
const Careers = () => {
  const navigate = useNavigate();

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    navigate(selectedValue);
  };
  return (
    <>
      <Container fluid className="careershome">
        {/* <NavBar /> */}
        {/* <Button /> */}
        <Container fluid className="careersdiv">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="text-centercareer text-center">
              <h1 className=" text-center careerhead">careers</h1>
              <p className="text-center careerpara">
                This is a place to grow, learn and connect. 
                <br />
                Everything that
                makes you who you are is welcome here.
              </p>
              <br />
              <br />
              <Row>
                <Col xs={12} sm={12} md={4} lg={4}></Col>
                <Col xs={12} sm={12} md={4} lg={4} className="text-center p-4">
                  <div className="border-career">
                    <select className="careers" onChange={handleChange}>
                      <option value="">Select a Career</option>
                      <option value="/techjs">UI/UX Designer</option>
                      <option value="/seojs">SEO Specialist</option>
                      <option>Sales Exicutive</option>
                      <option value="/digitalmarketingjs">
                        Digital Marketing Specialist
                      </option>
                    </select>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}></Col>
              </Row>
            </Col>
          </Row>
        </Container>

        {/* <Footer /> */}
      </Container>
    </>
  );
};

export default Careers;
