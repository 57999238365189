import React,{useEffect} from 'react'
import NavBar from '../../../compounds/AppBar/AppBar'
import Footer from '../../../compounds/Footer/Footer'

import './Toys.css'
import Aos from 'aos';
import 'aos/dist/aos.css';
import Toy from './Toy'
import Button from "../../../compounds/Button/Button";
function Toys() {

   
  return (
    <div className='Toy'>
      {/* <NavBar/> */}
      {/* <Button /> */}
    <Toy />
   
    </div>
  )
}

export default Toys
