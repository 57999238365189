import React from "react";
import NavBar from "../../../compounds/AppBar/AppBar";
import Footer from "../../../compounds/Footer/Footer";

import "./RawMaterial.css";
import Aos from "aos";
import "aos/dist/aos.css";
import RaMaterial from "./RaMaterial";
import Button from "../../../compounds/Button/Button";

function RawMaterial() {
  return (
    <div className="rawmaterial">
      <RaMaterial />
    </div>
  );
}

export default RawMaterial;
