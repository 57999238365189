import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";
import "./OverviewServices.css";
import Navbar from "../../compounds/AppBar/AppBar";
import Merchandise from "../../Images/management-side-1.webp";
// import wholesale from "../../Images/Bussines-min.webp";
import b2b2c from "../../Images/it-consulting.webp";
import Footer from "../../compounds/Footer/Footer";
import Button from "../../compounds/Button/Button";
import { Container, Row, Col } from "react-bootstrap";

import Overviewbg from "../../Images/over-view-bg.webp";
import check from "../../Images/check-boxblue.webp";
const OverviewServices = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);
  return (
    <>
      <Container fluid className="serv-banner">
        <Row>
          <Col className="p-0">
            <img
              src="{Overviewbg}"
              alt=""
              loading="lazy"
              className="overmin mt-5"
              srcset={`${Overviewbg} 300w, ${Overviewbg} 768w, ${Overviewbg} 1280w`}
              sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
            />
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="overviewsched ">
          <Col xs={12} sm={12} md={12} lg={12} className="merchandise-txt-container mt-3">
            <h1 className="text-center"> Supply Chain Solutions</h1>
            <p>
              Our Supply Chain Solutions offer expert management services and
              strategic sourcing to optimize your supply chain performance. We
              ensure efficiency, cost-effectiveness, and sustainability by
              leveraging advanced technology, robust risk management, and strong
              supplier relationships to provide comprehensive, tailored
              solutions.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            {/* <div id="Distribution"> */}
            <div
              className="merchandise-txt-container mt-2"
              // data-aos="zoom-in-left"
              // data-aos-delay="500"
              // data-aos-duration="500"
            >
              <h1> ⬢ Management Services Division </h1>
              <p>
                At L V L GROUPS & ENTERPRISES PVT LTD, our Management Services
                Division is dedicated to optimizing and streamlining your supply
                chain processes. Our team of seasoned experts employs
                cutting-edge technology and industry best practices to ensure
                your operations run smoothly and efficiently. We focus on
                delivering customized solutions that align with your business
                goals, drive cost savings, and enhance overall productivity.
              </p>
            </div>
            {/* </div> */}
          </Col>
          <Col
           xs={12} sm={12} md={4} lg={4}
            className="Distribution image-containerover"
          >
            <img
              src={Merchandise}
              alt=""
              loading="lazy"
              className="  image-with-shadowover "
              // data-aos="zoom-in-right"
              // data-aos-delay="500"
              // data-aos-duration="500"
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col
            xs={12} sm={12} md={4} lg={4}
            className="Distribution image-containerover mt-5"
          >
            {/* <div id=""> */}
            <img
              src={b2b2c}
              alt=""
              loading="lazy"
              className="image-with-shadowover "
              // data-aos="zoom-in-left"
              // data-aos-delay="500"
              // data-aos-duration="500"
            />{" "}
            {/* </div> */}
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            <div
              className="merchandise-txt-container mt-5"
              // data-aos="zoom-in-left"
              // data-aos-delay="500"
              // data-aos-duration="500"
            >
              <h1>⬢ Strategic Sourcing</h1>
              <p>
                {" "}
                Strategic Sourcing at L V L GROUPS & ENTERPRISES PVT LTD is
                about more than just procurement; it's about forging strategic
                partnerships and driving long-term value. Our approach focuses
                on total cost of ownership, ensuring that every sourcing
                decision contributes to your overall business strategy. We
                leverage our extensive supplier network and market intelligence
                to deliver the best possible outcomes for our clients.{" "}
              </p>
            </div>
          </Col>
        </Row>
        <br />
        <Row className="mt-5">
          <Row className="distract">
            <Col xs={12} sm={12} md={6} lg={6} className="distract">
              <h4 className="text-center">Process Optimization</h4>
              <p>
                We perform in-depth research to analyze market trends and
                supplier capabilities, identifying the best sourcing
                opportunities and strategies.
              </p>
              <h4 className="text-center">Supplier Selection</h4>
              <p>
                We rigorously evaluate potential suppliers, assessing financial
                stability, compliance, and performance history to form strong
                partnerships.
              </p>
              <h4 className="text-center">Cost Management</h4>
              <p>
                We negotiate competitive pricing, implement cost-saving
                initiatives, and monitor supplier compliance to optimize cost
                efficiency.
              </p>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="distract">
              <h4 className="text-center">Contract Management</h4>
              <p>
                We draft and manage supplier contracts, conduct regular reviews
                and renegotiations, and ensure adherence to terms to mitigate
                risks.
              </p>
              <h4 className="text-center">Sustainability and Ethics</h4>
              <p>
                We prioritize sourcing from environmentally and socially
                responsible suppliers, implementing sustainable practices and
                ensuring ethical labor standards.
              </p>
            </Col>
          </Row>
        </Row>

        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="">
            <div className="our-team-assist">
              <h1 className="text-center benifits">Benefits</h1>

              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <h4 className=" text-center">Cost Reduction</h4>
                  <p>
                    Achieve significant savings through optimized processes and
                    efficient resource utilization
                  </p>
                  <h4 className=" text-center">Enhanced Efficiency</h4>
                  <p>
                    Streamlined operations leading to faster turnaround times
                    and improved productivity.
                  </p>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <h4 className=" text-center">Scalability</h4>
                  <p>
                    Solutions designed to grow with your business, adapting to
                    changing needs and demands.
                  </p>
                  <h4 className=" text-center">Risk Mitigation</h4>
                  <p>
                    Reduced vulnerability to supply chain disruptions and
                    compliance issues.
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className="">
              <h1 className="benifits text-center">
                Our team can assist your organization in the following ways
              </h1>
              <p>
                <img
                  src={check}
                  alt=""
                  loading="lazy"
                  style={{ width: "auto", height: "25px" }}
                />{" "}
                We can find low cost providers in any area of the world to
                produce your product offerings per your specifications
              </p>
              <p>
                <img
                  src={check}
                  alt=""
                  loading="lazy"
                  style={{ width: "auto", height: "25px" }}
                />{" "}
                We can perform blind quality audits at any location around the
                globe at intervals per your specifications
              </p>
              <p>
                {" "}
                <img
                  src={check}
                  alt=""
                  loading="lazy"
                  style={{ width: "auto", height: "25px" }}
                />{" "}
                We can assist in creating and articulating your project
                specifications and characteristics in local languages to ensure
                nothing is lost in translation
              </p>
              <p>
                <img
                  src={check}
                  alt=""
                  loading="lazy"
                  style={{ width: "auto", height: "25px" }}
                />{" "}
                We can manage the cultural differences that result in
                international business transactions due to the lack of knowledge
                about local business practices
              </p>
              <p>
                <img
                  src={check}
                  alt=""
                  loading="lazy"
                  style={{ width: "auto", height: "25px" }}
                />{" "}
                We can assist in mitigating cost overruns associated with
                international business transactions
              </p>
              <p>
                <img
                  src={check}
                  alt=""
                  loading="lazy"
                  style={{ width: "auto", height: "25px" }}
                />
                {"  "} We can leverage our team's international business
                knowledge and company resources to yield positive financial
                results for your organization.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OverviewServices;
