import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";
import "./Services.css";
import Navbar from "../../compounds/AppBar/AppBar";

import Footer from "../../compounds/Footer/Footer";
import Button from "../../compounds/Button/Button";
import OverviewServices from "./OverviewServices";

function Services() {
  return (
    <div className="service-page">
      {/* <Navbar /> */}
      {/* <Button /> */}
      <OverviewServices />
     

      {/* <Footer /> */}
    </div>
  );
}

export default Services;

