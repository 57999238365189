import React from 'react'

const LoadingSpinner = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

export default LoadingSpinner
