import React, { useEffect, useState } from "react";

import Navbar from "./../../compounds/AppBar/AppBar";
import "./GetStarted.css";
import { joinUsDetails } from "../../../src/Services/ApiService";
import "aos/dist/aos.css";
import Footer from "../../compounds/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
function GetStarted() {
  const [formData, setFormData] = useState({
    Name: "",
    BusinessName: "",
    Country: "",
    Gst: "",
    Abn: "",
    ExisistingBusiness: "",
    BusinessAddress: "",
    State: "",
    City: "",
    StateYourInterest: "",
    ProductServices: "",
    ITServices: "",
    EmailID: "",
    PhoneNumber: "",
    PostalCode: "",
    AdditionalComments: "",
    LookingFor: "",
    Upload: " ",
  });

  const [error, setError] = useState("");
  const [country, setCountry] = useState("");
  const [interest, setInterest] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setCountry(selectedCountry);
    setFormData({
      ...formData,
      Country: selectedCountry,
      Gst: "", // Clear GST if country changes
      Abn: "", // Clear ABN if country changes
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        Upload: file,
      });
      setError("");
    } else {
      setError("Please select a valid file.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    joinUsDetails(formData)
      .then(
        (response) => {
          console.log("Success:", response.data);
          console.log(formData, "formdata");
          // Handle success (e.g., show a success message, reset form, etc.)
        },
        setFormData({
          Name: "",
          BusinessName: "",
          Country: "",
          Gst: "",
          Abn: "",
          ExisistingBusiness: "",
          BusinessAddress: "",
          State: "",
          City: "",
          StateYourInterest: "",
          ProductServices: "",
          ITServices: "",
          EmailID: "",
          PhoneNumber: "",
          PostalCode: "",
          AdditionalComments: "",
          LookingFor: "",
          Upload: " ",
        })
      )
      .catch((error) => {
        console.error("Error:", error);
        // Handle error (e.g., show an error message)
      });
    setFormData({
      Name: "",
      BusinessName: "",
      Country: "",
      Gst: "",
      Abn: "",
      ExistingBusiness: "",
      BusinessAddress: "",
      State: "",
      StateYourInterest: "",
      ProductServices: "",
      ITServices: "",
      EmailID: "",
      PhoneNumber: "",
      PostalCode: "",
      AdditionalComments: "",
      LookingFor: "",
      Upload: "",
    });
  };

  return (
    <div>
      {/* <Navbar /> */}
      <div className="contact-form-container">
        <Container>
          <Row className="">
            <Col xs={12} sm={12} md={12} lg={12} className="">
              <form onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="name">Name:</label>
                  <input
                    type="text"
                    name="Name"
                    placeholder="Name"
                    value={formData.Name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="businessName">Business Name:</label>
                  <input
                    type="text"
                    placeholder="Business Name"
                    name="BusinessName"
                    value={formData.BusinessName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="country">Country:</label>
                  <select
                    id="country"
                    name="Country"
                    value={formData.Country}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      Select Country
                    </option>
                    <option value="USA">USA</option>
                    <option value="INDIA">India</option>
                    <option value="AUSTRALIA">Australia</option>
                  </select>
                </div>
                {formData.Country === "INDIA" && (
                  <div>
                    <label htmlFor="gst">GST:</label>
                    <input
                      type="text"
                      id="gst"
                      name="Gst"
                      value={formData.Gst}
                      onChange={handleChange}
                    />
                  </div>
                )}
                {formData.Country === "AUSTRALIA" && (
                  <div>
                    <label htmlFor="abn">ABN:</label>
                    <input
                      type="text"
                      id="abn"
                      name="Abn"
                      value={formData.Abn}
                      onChange={handleChange}
                    />
                  </div>
                )}
                <div>
                  <label htmlFor="existingBusiness">Existing Business:</label>
                  <input
                    type="text"
                    id="ExisistingBusiness"
                    name="ExisistingBusiness"
                    value={formData.ExisistingBusiness}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="businessAddress">Business Address:</label>
                  <input
                    type="text"
                    id="businessAddress"
                    name="BusinessAddress"
                    value={formData.BusinessAddress}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="state">City:</label>
                  <input
                    type="text"
                    id="City"
                    name="City"
                    value={formData.City}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="state">State:</label>
                  <input
                    type="text"
                    id="state"
                    name="State"
                    value={formData.State}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="interest">Your Interest:</label>
                  <select
                    id="interest"
                    name="StateYourInterest"
                    value={formData.StateYourInterest}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      Select Interest
                    </option>
                    <option value="Product Services">Product Services</option>
                    <option value="IT Services">IT Services</option>
                    <option value="It&product services">Both</option>
                  </select>
                </div>
                {formData.StateYourInterest === "Product Services" && (
                  <div>
                    <label htmlFor="productType">
                      Types of Product Services:
                    </label>
                    <select
                      id="productType"
                      name="ProductServices"
                      value={formData.ProductServices}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Select Product Type
                      </option>
                      <option value="Office & Package Suppliers">
                        Office & Package Suppliers
                      </option>
                      <option value="Clothing & Apparel">
                        Clothing & Apparel
                      </option>
                      <option value="Raw Materials">Raw Materials</option>
                      <option value="Toys">Toys</option>
                    </select>
                  </div>
                )}
                {formData.StateYourInterest === "IT Services" && (
                  <div>
                    <label htmlFor="itServiceType">Types of IT Services:</label>
                    <select
                      id="itServiceType"
                      name="ITServices"
                      value={formData.ITServices}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Select IT Service Type
                      </option>
                      <option value="Web Design & Application">
                        Web Design & Application
                      </option>
                      <option value="Digital Marketing">
                        Digital Marketing
                      </option>
                      <option value="CRM Integration">CRM Integration</option>
                      <option value="Business & IT Consulting">
                        Business & IT Consulting
                      </option>
                    </select>
                  </div>
                )}
                {formData.StateYourInterest === "It&product services" && (
                  <>
                    <div>
                      <label htmlFor="productType">
                        Types of Product Services:
                      </label>
                      <select
                        id="productType"
                        name="ProductServices"
                        value={formData.ProductServices}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select Product Type
                        </option>
                        <option value="Office & Package Suppliers">
                          Office & Package Suppliers
                        </option>
                        <option value="Clothing & Apparel">
                          Clothing & Apparel
                        </option>
                        <option value="Raw Materials">Raw Materials</option>
                        <option value="Toys">Toys</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor="itServiceType">
                        Types of IT Services:
                      </label>
                      <select
                        id="itServiceType"
                        name="ITServices"
                        value={formData.ITServices}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select IT Service Type
                        </option>
                        <option value="Web Design & Application">
                          Web Design & Application
                        </option>
                        <option value="Digital Marketing">
                          Digital Marketing
                        </option>
                        <option value="CRM Integration">CRM Integration</option>
                        <option value="Business & IT Consulting">
                          Business & IT Consulting
                        </option>
                      </select>
                    </div>
                  </>
                )}
                <div>
                  <label htmlFor="email">Email Id:</label>
                  <input
                    type="email"
                    id="email"
                    name="EmailID"
                    value={formData.EmailID}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="phoneNumber">Phone Number:</label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="PhoneNumber"
                    value={formData.PhoneNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="postalCode">Postal Code:</label>
                  <input
                    type="text"
                    id="postalCode"
                    name="PostalCode"
                    value={formData.PostalCode}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="additionalComments">
                    Additional Comments:
                  </label>
                  <textarea
                    id="additionalComments"
                    name="AdditionalComments"
                    value={formData.AdditionalComments}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="enquiryType">Enquiry Type:</label>
                  <select
                    id="enquiryType"
                    name="LookingFor"
                    value={formData.LookingFor}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      Select Enquiry Type
                    </option>
                    <option value="Quote">Request a Quote</option>
                    <option value="General Enquire">General Enquire</option>
                    <option value="Product Purchase">
                      Looking For a Product Purchase
                    </option>
                    <option value="Sell to our Company">
                      Interested in selling to our company
                    </option>
                    <option value="Support Request">
                      Need help with a support request
                    </option>
                    <option value="Product Information">
                      Product Information
                    </option>
                    <option value="IT Information">IT Information</option>
                    <option value="Interested in a Partnership">
                      Interested in a Partnership
                    </option>
                  </select>
                </div>
                <div>
                  <label htmlFor="fileUpload">Upload Files:</label>
                  <input
                    type="file"
                    id="fileUpload"
                    name="fileUpload"
                    accept=".jpg,.jpeg,.png,.mp4,.mov,.pdf,.doc,.xml,.csv"
                    onChange={handleFileChange}
                  />
                  {error && <p style={{ color: "red" }}>{error}</p>}
                </div>
                <button type="submit">Submit</button>
              </form>
            </Col>
           
          </Row>
        </Container>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default GetStarted;
