import React from 'react'
import './Home.css'
import Navbar from '../../compounds/AppBar/AppBar'
import Herosection from '../../compounds/Herosection/Herosection'
// import Textcontent from '../../compounds/TextContent/Textcontent'
// import Containersection from '../../compounds/Containersection/Containersection' 
import ItSourcing from '../../compounds/ItSourcing/ItSourcing'
import Footer from '../../compounds/Footer/Footer'
import Contactform from '../../compounds/ContactForm/ContactForm'
import ProductSourcing from '../../compounds/ProductSourcingContainerHome/ProductSourcingContainerHome'
import TextContainer from '../../compounds/TextContainer/TextContainer'
import Hero2section from '../../compounds/Hero2Section/Hero2Section'
import Button from '../../compounds/Button/Button'
import WhyChoose from '../../compounds/WhyChooseUs/WhyChoose'


const Home = () => {
  return (
    <div className='home'>
      {/* <Navbar/> */}
      <Herosection/>
      {/* <Button/> */}
      <TextContainer/> 
      <ItSourcing/>
      <WhyChoose/>
      <ProductSourcing/>
      <Hero2section/>
      
      {/* <Footer />  */}
      
      
    </div>
  )
}

export default Home
