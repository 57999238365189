import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Webimg2 from "../../../Images/Analytics.webp";
import Accessibility from "../../../Images/web-card-accessibility-min.webp";
import COntent from "../../../Images/web-card-content-min.webp";
import navigation from "../../../Images/web-card-navigation-min.webp";
import responsive from "../../../Images/web-card-responsive-min.webp";
import security from "../../../Images/web-card-security-min.webp";
import userfriendly from "../../../Images/web-card-userfriendly-min.webp";
import news from "../../../Images/web-blog-news-min.webp";
import broucher from "../../../Images/web-broucher-min.webp";
import ecommerce from "../../../Images/web-ecommerce-min.webp";
import speed from "../../../Images/web-speen-card-min.webp";
import education from "../../../Images/web-educate.webp";
import portfol from "../../../Images/portpholio.webp"
import Portfolio from "../../../Images/web-card-accessibility-min.webp";
import ContactUsForm from "../../../pages/ContactUsForm/ContactUsForm";
import government from "../../../Images/government-web-min.webp";
import personal from "../../../Images/personal-web-min.webp";
import corporate from "../../../Images/corporate-web-min.webp";

import CustomModel from "../../../compounds/Model/Model";
import Buttons from "../../../compounds/Button/Button";
import NavBar from "../../../compounds/AppBar/AppBar";
import Footer from "../../../compounds/Footer/Footer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Aos from "aos";
import "aos/dist/aos.css";
import "./Web.css";
import { useNavigate } from "react-router-dom";

const Web = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the desired path
    navigate('/contact-us');
  };
  useEffect(() => {
    Aos.init();
  }, []);
  const [showModel, setShowModel] = useState(false);
  const handleClose = () => setShowModel(false);
  const handleOpen = () => setShowModel(true);
  return (
    <>
      {/* <NavBar />
      <Buttons /> */}
      <Container fluid className="webmed container-fluid ">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className=" headbg ">
           
            <h1 className=" text-center text-white mt-5">
              Transform Your Business with a Stunning Website{" "}
            </h1>
            <br />
            <p className="text-center  ">
              Welcome to <span>L V L GROUPS & ENTERPRISES PVT LTD</span>, where
              we transform your ideas into exceptional digital experiences
              <br />
              Our mission is to deliver cutting-edge web design and application
              development solutions that drive growth and innovation for our
              clients
            </p>

            {/* <h2 className="Webh2 text-center">
              Dominate the Digital Landscape with Unrivaled Web Design and
              Unstoppable Applications{" "}
            </h2> */}
            {/* </Col> */}
          </Col>
        </Row>
      </Container>
      <Container fluid className="">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="mt-5">
            <h2 className="Webh2 text-center">
              Dominate the Digital Landscape with Unrivaled Web Design and
              Unstoppable Applications{" "}
            </h2>
            <p className="">
              In today’s digital landscape, a visually captivating and
              user-friendly website is not just an advantage—it’s essential. A
              well-designed website tailored to your target audience can
              significantly boost your business’s visibility, engagement, and
              growth. At our Web Design Agency, we excel in creating diverse
              types of websites, each tailored to meet your specific business
              needs. Our team of skilled designers and developers combines
              creativity with technical expertise to deliver cutting-edge
              websites that stand out in the crowded online space.
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="mt-4 section-3colw ">
          <Col xs={12} sm={12} md={12} lg={12}>
            {/* <div className="is-stickeys"> */}
            <h2 className="Webh2 text-center m-5 ">
              Here’s a look at the types of websites we create:
            </h2>
            {/* </div> */}
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center">ECommerce Website</h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  The purpose of this website is to establish a professional
                  online presence with an elegant design and clear descriptions
                  of products or services, along with easily accessible contact
                  information. It is ideally suited for small to medium-sized
                  businesses aiming to promote their offerings and build
                  credibility.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={ecommerce}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={corporate}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow "
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center">Corporate Websites</h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  The purpose of this website is to represent large
                  organizations and institutions with a professional design that
                  offers comprehensive information about services and robust
                  security features. It is ideally suited for banks, law firms,
                  financial institutions, and other large entities that require
                  a sophisticated online presence.
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center">Educational Websites</h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  The purpose of this website is to provide learning resources
                  and courses through a well-organized platform featuring course
                  listings, interactive content, and user-friendly navigation.
                  It is ideally suited for educational institutions, online
                  learning platforms, and organizations that offer training and
                  workshops.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={education}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={government}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow "
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center">Government Websites</h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  The purpose of this website is to deliver information and
                  services to the public with an accessible design, clear
                  information structure, and secure data handling. It is ideally
                  suited for government agencies and public sector organizations
                  that need to communicate effectively with citizens.
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center">Portfolio Websites</h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  The purpose of this website is to showcase work and attract
                  potential clients by featuring high-quality image galleries,
                  detailed project descriptions, and client testimonials. It is
                  ideally suited for artists, photographers, designers, and
                  other professionals who need to highlight their skills and
                  showcase their previous projects.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={portfol}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={broucher}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow "
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center">Brochure Websites:</h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  The purpose of this website is to establish a professional
                  online presence with an elegant design that features clear
                  descriptions of products or services and provides accessible
                  contact information. It is ideally suited for small to
                  medium-sized businesses seeking to promote their offerings and
                  build credibility.
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center">Blogs & News Websites</h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  The purpose of this website is to share timely content and
                  updates through a robust content management system, social
                  media integration, and a responsive design. It is ideally
                  suited for publishers, journalists, and content creators who
                  aim to engage with their audience on a regular basis.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={news}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={personal}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow "
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center mb-0 mt-2">
                  Personal Websites
                </h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  The purpose of this website is to share personal information
                  and achievements with a personalized design that includes
                  resume sections and blogs. It is ideally suited for
                  individuals who want to showcase their personal brand, share
                  their stories, or maintain a personal blog.
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            {/* border-bottom: thick double #32a1ce; */}
            <div className="is-stickey">
              <h2 className="webdesign mt-3">Why Choose Us?</h2>
              <p>
                <span className="webdesign">Mastery in Web Design:</span> With a
                wealth of experience in both design and development, our team
                delivers websites that are as functional as they are
                eye-catching. We blend expert knowledge with creativity to
                produce outstanding results.
              </p>
              <p>
                <span className="webdesign">Tailored for Your Brand:</span> We
                specialize in creating custom solutions that perfectly align
                with your business objectives. Your website will capture your
                brand’s essence and be crafted to meet your unique needs.
              </p>
              <p>
                <span className="webdesign">Future-Forward Solutions: </span>We
                embrace the latest trends and technologies to provide
                cutting-edge solutions. Our forward-thinking approach keeps your
                business at the forefront of the digital landscape.
              </p>
              <p>
                <span className="webdesign">Support:</span> From initial
                consultation to ongoing maintenance, we’re here to support you
                every step of the way.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="mt-5 ">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <h2 className="Webh2 text-center p-4">
              {" "}
              Key Website Features for Optimal Performance
            </h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={3} lg={3} className="mt-2 text-center">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img
                    src={userfriendly}
                    alt=""
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "1rem",
                    }}
                  />
                  {/* <img src="img_avatar.webp" alt="Avatar" style="width:300px;height:300px;"> */}
                </div>
                <div class="flip-card-back">
                  <h4>User-Friendly Design</h4>
                  <p>
                    The website features a clean, intuitive layout with a focus
                    on UI and UX. It uses whitespace, clear typography, and CTAs
                    to guide interactions, enhancing usability and ensuring a
                    consistent experience across devices.
                  </p>
                  {/* <p>We love that guy</p> */}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3} className="mt-2 text-center">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img
                    src={Accessibility}
                    alt=""
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "1rem",
                    }}
                  />
                  {/* <img src="img_avatar.webp" alt="Avatar" style="width:300px;height:300px;"> */}
                </div>
                <div class="flip-card-back">
                  <h4>Accessibility</h4>
                  <p>
                    The website follows WCAG 2.1 standards, using semantic HTML,
                    ARIA landmarks, and keyboard navigability to ensure
                    accessibility for users with disabilities. This enhances
                    inclusivity and complies with accessibility regulations.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3} className="mt-2 text-center">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img
                    src={navigation}
                    alt=""
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "1rem",
                    }}
                  />
                  {/* <img src="img_avatar.webp" alt="Avatar" style="width:300px;height:300px;"> */}
                </div>
                <div class="flip-card-back">
                  <h4>Navigation</h4>
                  <p>
                    The website features hierarchical and breadcrumb navigation,
                    drop-down menus, and AJAX for dynamic content loading. This
                    streamlines navigation, aids quick information retrieval,
                    and improves SEO with effective internal linking.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3} className="mt-2 text-center">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img
                    src={responsive}
                    alt=""
                    loading="lazy"
                    className="  "
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "1rem",
                    }}
                  />
                  {/* <img src="img_avatar.webp" alt="Avatar" style="width:300px;height:300px;"> */}
                </div>
                <div class="flip-card-back">
                  <h4 className="text-whitecarda ">Responsive Layouts</h4>
                  <p>
                    The website uses fluid grid layouts, flexible images, and
                    media queries, along with CSS Flexbox and Grid systems, to
                    adapt to different screen sizes and orientations. This
                    ensures full functionality and visual appeal across all
                    devices, enhancing user engagement and accessibility.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12} sm={12} md={3} lg={3} className="mt-2 text-center">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img
                    src={COntent}
                    alt=""
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "1rem",
                    }}
                  />
                  {/* <img src="img_avatar.webp" alt="Avatar" style="width:300px;height:300px;"> */}
                </div>
                <div class="flip-card-back">
                  <h4>Content</h4>
                  <p>
                    The website uses fluid grid layouts, flexible images, and
                    CSS Flexbox and Grid systems to adapt to all screen sizes,
                    ensuring functionality and visual appeal across devices,
                    enhancing user engagement and accessibility.
                  </p>
                  {/* <p>We love that guy</p> */}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3} className="mt-2 text-center">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img
                    src={speed}
                    alt=""
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "1rem",
                    }}
                  />
                  {/* <img src="img_avatar.webp" alt="Avatar" style="width:300px;height:300px;"> */}
                </div>
                <div class="flip-card-back">
                  <h4>Speed</h4>
                  <p>
                    The website optimizes performance with image compression,
                    minified CSS and JavaScript, CDNs, and caching strategies,
                    reducing load times, enhancing user experience, and boosting
                    search engine rankings.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3} className="mt-2 text-center">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img
                    src={security}
                    alt=""
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "1rem",
                    }}
                  />
                  {/* <img src="img_avatar.webp" alt="Avatar" style="width:300px;height:300px;"> */}
                </div>
                <div class="flip-card-back">
                  <h4>Security</h4>
                  <p>
                    The website uses SSL/TLS encryption and follows best
                    security practices, including updates, firewalls, and
                    protection against SQL injection, XSS, and CSRF attacks.
                    Security plugins for CMS-based sites further protect user
                    data and ensure regulatory compliance.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3} className="mt-2 text-center">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img
                    src={Webimg2}
                    alt=""
                    loading="lazy"
                    className="  "
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "1rem",
                    }}
                  />
                  {/* <img src="img_avatar.webp" alt="Avatar" style="width:300px;height:300px;"> */}
                </div>
                <div class="flip-card-back">
                  <h4 className="text-whitecarda ">Analytics</h4>
                  <p>
                    The website integrates Google Analytics, Google Tag Manager,
                    and heatmaps to track user interactions and performance.
                    Event tracking and conversion goals provide insights for
                    data-driven decisions and continuous optimization.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row className="our-team-assistwebssubscribe">
          <Col xs={12} sm={12} md={8} lg={8}>
            <h2 className="text-center text-white p-3">
              Grow Your Business With L V L groups
            </h2>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} className="text-center">
            <Button
              className="btn btn-primary classifybtn mt-3"
              onClick={handleClick}
            >
              {" "}
              Contact Us
            </Button>
            
          </Col>
        </Row>
      </Container>
     
    </>
  );
};

export default Web;
