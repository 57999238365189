import React, { useEffect } from "react";
import "./ProductSourcing.css";
import AppBar from "../../compounds/AppBar/AppBar";
import Footer from "../../compounds/Footer/Footer";
import Design from "../../Images/Designer.webp";
import Expertise from "../../Images/Expertise.webp";
import Branding from "../../Images/personal-branding.webp";
import WhyFromIndia from "../../Images/yfromindia.webp";
import Aos from "aos";
import "aos/dist/aos.css";

function ProductSourcing() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      {/* <AppBar /> */}
      <div className="product-src">
        <div className="p-src-txt-container">
          <h1> Product Sourcing</h1>
          <h2 style={{ marginLeft: "3%" }}>
            “Quality Products at Affordable Prices”
          </h2>
          <div className="p-src-txt-container">
            <p>Team up with us to Save up to 30% on Goods.</p>
            <p>
              {" "}
              we specialise in Merchandise(Export & Import) as middlemen &
              Business Consulting to help you find best resources from India in
              sourcing quality products straight from manufacturers and
              suppliers directly to your warehouse to grow your business.
            </p>
            <p>
              We bring extensive knowledge and commitment to successfully fulfil
              all your sourcing needs.
            </p>
            <p>
              Some of the products we work with include Office & Business
              Supplies, Garments & Textiles, Agri Products, raw materials,
              electrical equipment, machinery, and much more.
            </p>
          </div>
          <div className="p-src-txt-container-hero-div1">
            <div
              className="image-div1"
              data-aos="zoom-in-up"
              data-aos-delay="500"
              data-aos-duration="1000"
            >
              {" "}
              <img className="div-img1" loading="lazy" src={Design} />
            </div>
            <div>
              <h4>We Can Make Your Life Easier</h4>
              <p>
                We understand how challenging it is for a business to source
                products from a foreign country, especially when having a
                communication barrier.
              </p>
              <h4>
                Conversations between you and manufacturer can be very
                unproductive and frustrating
              </h4>
              <p>
                In addition to the language barrier looking for the right
                factory for your product is not that easy as the industry is
                replete with incompetent manufacturers that may seem good on the
                surface, but are actually a nightmare to deal with.
              </p>{" "}
            </div>
          </div>
          <div className="p-src-txt-container-hero-div1">
            <div>
              <h4>
                A cutting edge solution to help you in making the deal with our
                contacts, expertise and experience to have an automated
                inventory management process
              </h4>
              <p>
                Enables you to fully devote yourself to your business. No need
                to fly to India and deal with the language barrier between you
                and your prospective manufacturers and suppliers. We do that for
                you.
              </p>
              <p>
                Our overseas team of sourcing agents will handle all of the
                communications on your behalf. All you have to do is provide us
                the right information to your requirements, quantity, and target
                budget, our team take care of the rest.
              </p>
              <p>
                As for the calibre of the businesses & startup’s we work with,
                we have curated a list of qualified manufacturers from
                government of India that follow good manufacturing practices and
                have high ethical standards to source something to you.
              </p>
              <p>
                We will find you the qualified and certified manufacturers,
                suppliers or vendors that can make your product with quality &
                perfection at an affordable price with in your budget.
              </p>
            </div>
            <div
              className="image-div2"
              data-aos="zoom-in-up"
              data-aos-delay="500"
              data-aos-duration="1000"
            >
              {" "}
              <img className="div-img2" loading="lazy" src={Expertise} />
            </div>
          </div>

          <div className="p-src-txt-container-hero-div1">
            <div
              className="image-div3"
              data-aos="zoom-in-up"
              data-aos-delay="500"
              data-aos-duration="1000"
            >
              {" "}
              <img className="div-img1" loading="lazy" src={Branding} />
            </div>
            <div>
              <h4>
                We can also private label your products with your brand and
                startups
              </h4>
              <p>
                Premium and exclusive packaging, offering private labelling.
                Providing a way to promote and market your business to stand out
                from your competitors.
              </p>
              <h4>Premium & Quality Goods at Possible Affordable Prices</h4>
              <p>
                As a sourcing company it’s out duty to work with proven
                manufacturers, suppliers and vendors. A guarantee in receiving
                quality products as specified to your warehouse directly based
                on your choosen inventory cycle.
              </p>
            </div>
          </div>
          <div className="p-src-txt-container-hero-div1">
            <div>
              <h4>Why Import from India and Australia</h4>
              <p>
                In most cases, it’s more profitable for companies to source and
                import products from oversea’s instead of manufacturing the
                goods demostically.
              </p>
              <p>
                The main reasons for this are that over seas manufacturers have
                a lower overhead, a higher production capability, and
                Cost-Efficient Labour.
              </p>
              <p>
                Although manufacturing costs are affordable in asia-pacific, the
                quality of the products are not compromised.
              </p>
              <p>
                From India and Australia, we ensure that all parts and
                components are according to your specifications at an affordable
                price with premium quality within budget.
              </p>
              <p>
                It’s all about finding the right manufacturers and providing an
                cutting edge solution to put you forward with premium inventory
                on time with business.
              </p>

              <p>
                International Product Sourcing will ensure a smooth seamless
                order process at a very competitive price from the initial
                product enquiry right through until the goods are delivered to
                your warehouse. Contact us to find out more.
              </p>
            </div>
            <div
              className="image-div3"
              data-aos="zoom-in-up"
              data-aos-delay="500"
              data-aos-duration="1000"
            >
              {" "}
              <img className="div-img2" loading="lazy" src={WhyFromIndia}  />
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default ProductSourcing;
