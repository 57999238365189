import React, { useEffect } from "react";
import NavBar from "../../../compounds/AppBar/AppBar";
import Footer from "../../../compounds/Footer/Footer";
// import ofcpkg from "../../../Images/ofc-pkg.webp";
// import wholesale from "../../../Images/wholesale2.webp";
// import Bcons from "../../../Images/B cons.webp";
import "./OfcandPkg.css";
import Aos from "aos";
import "aos/dist/aos.css";
import OfficePackage from "./OfficePackage";
import Button from "../../../compounds/Button/Button";


function OfcandPkg() {
  

  return (
    <div className="package">
      
      <OfficePackage />
     
    </div>
  );
}

export default OfcandPkg;

