import React, { useState, useEffect } from "react";
import Typewriter from "typewriter-effect"; // Import the Typewriter library
import "./Herosection.css";
// import heroimg from "./../../Images/e-canva-mob.webp";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
// import logo from "../../Images/logoglobe.jpg";
import { TypeAnimation } from "react-type-animation";
import { Row, Container, Col, Button, Card } from "react-bootstrap";
import imgbg from "../../Images/Group 1 (7).png";
import check from "../../Images/check-box.webp";
import { useNavigate } from "react-router-dom";
const HeroSection = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the desired path
    navigate("/contact-us");
  };
 
  return (
    <>
      <Container fluid>
        <Row className="homebg-content">
          <Col xs={12} sm={12} md={6} lg={6}>
            <img src={imgbg} loading="lazy" alt="HomeImage" className="imghome" />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="mt-3">
            <h1
              className=" text-white headingbg mt-5"
              style={{
                fontVariantCaps: "all-petite-caps",
                fontSize: "56px !important;",
              }}
            >
              Our experience is
              <br /> your advantage
            </h1>
            <h6
              className="text-black"
              style={{ fontSize: "22px !important", fontWeight: "700" }}
            >
              Better solution, to a better you
            </h6>
            <hr className="hrbold" />
            <h5 className="hero-text-highlight text-white ">
              Tailored Solutions for Our Valued Members
            </h5>

            <ul className="p-0">
              <li className="bglist">
                <img
                  src={check}
                  alt=""
                  loading="lazy"
                  style={{ width: "auto", height: "25px" }}
                />{" "}
                <TypeAnimation
                  style={{ fontSize: "22px" }}
                  sequence={["Welcome to our website!", 800, ""]}
                  repeat={Infinity}
                />
              </li>
              <li className="bglist">
                <img
                  src={check}
                  alt=""
                  loading="lazy"
                  style={{ width: "auto", height: "25px" }}
                />{" "}
                <TypeAnimation
                  style={{ fontSize: "22px" }}
                  sequence={["Explore our services.", 1000, ""]}
                  repeat={Infinity}
                />
              </li>
            </ul>
            <div className="buttons-container mt-4">
              <span>
                &nbsp;
                <Button
                  className="hero-button btn btn-light"
                  variant="light"
                  onClick={handleClick}
                >
                  CONTACT US <IoIosArrowForward className="arrow-icon" />
                </Button>
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HeroSection;
