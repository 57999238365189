import React from "react";
// import Logo from "../../Images/LVL_FINAL_LOGO_PSD_WHITE-removebg-preview.webp";
import Logo from "../../Images/footer1-logo.png";
import { redirect, useNavigate } from "react-router-dom";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
} from "react-icons/fa";

import { FaSquareXTwitter } from "react-icons/fa6";
import "./Footer.css";
import { Col, Container, Row } from "react-bootstrap";

function Footer() {
  const navigate = useNavigate();
  return (
    <>
      <Container fluid className="footer">
        <div className="footer-container ">
          <Row className="mt-2 displaypositions">
            <Col xs={12} sm={12} md={3} lg={3}>
              <div className="section-1 connect" >
                <img
                  src={Logo}
                  loading="lazy"
                  alt="logotype"
                  style={{ height: "95px", width: "auto" }}
                />
                <div>
                  <h4 className="text-white connect">Connect With Us</h4>
                </div>
                <div className="social-icon ">
                  <span className="grid-item-icons iconslinked">
                    <a
                      href="/Error"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Know More about us In Linkedin"
                    >
                      <FaLinkedin size={40} color="white" />
                    </a>
                  </span>
                  <span className="grid-item-icons iconslinked">
                    <a
                      href="https://www.instagram.com/l_v_l__groups_and_enterprises"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Follow us on Instagram"
                    >
                      <FaInstagramSquare size={40} color="white" />
                    </a>
                  </span>
                  <span className="grid-item-icons iconslinked">
                    <a
                      href="/Error"
                      rel="noopener noreferrer"
                      target="_blank"
                      aria-label="Know More about us In Facebook"
                    >
                      <FaFacebookSquare size={40} color="white" />
                    </a>
                  </span>
                  <span className="grid-item-icons iconslinked">
                    <a
                      href="/Error"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Know More about us In Twitter "
                    >
                      <FaSquareXTwitter size={40} color="white" />
                    </a>
                  </span>
                </div>
                <div>
                  <span className="email-txts text-white">
                    <a href="mailto:info@lvlgroups.com" className="email-txts">
                      info@lvlgroups.com
                    </a>
                  </span>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3}>
              <div className="section-2">
                <p
                  style={{
                    color: "#000",
                    fontSize: "1rem",
                    paddingLeft: "25%",
                  }}
                >
                  <strong className="text-white">Australia</strong>
                </p>
                <span style={{ color: "#000" }}>
                  <ul className="footercontent">
                    <li>L V L GROUPS & ENTERPRISES PVT LTD</li>
                    <li>ABN 366 728 24 403</li>
                  </ul>
                  <br />
                  <p style={{ paddingLeft: "15%" }}>
                    <strong className="text-white text-center">Address:</strong>
                  </p>
                  <ul className="footercontent">
                    <li>
                      273B Brandon park, Wheelers Hill VIC 3150, Australia
                    </li>
                    <li className="text-left text-white">Tel: +61 406185055</li>
                  </ul>
                </span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3}>
              <div className="section-2">
                <span style={{ color: "#000" }}>
                  <p style={{ paddingLeft: "15%" }}>
                    <strong className="text-white">India</strong>
                  </p>
                  <ul className="footercontent">
                    <li>L V L ENTERPRISES</li>
                    <li>GST: 36AWYPL4012D1ZI</li>
                    <br />
                  </ul>
                  <p style={{ paddingLeft: "15%" }}>
                    <strong className="text-white text-center">Address</strong>
                  </p>
                  <ul className="footercontent">
                    <li>
                      114, 1st Floor, Manjeera Trinity Corporate, KPHB Phase 3,
                      Hyderabad, Telangana 500072
                    </li>
                    <li className="text-left text-white">
                      Tel: +91 8019328988
                    </li>
                    <li className="text-left text-white">
                      Tel: +91 8058555999
                    </li>
                  </ul>
                  {/* <p>Our Branch in india</p> */}
                </span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3}>
              <div className="section-2">
                <ul className="about-contact">
                  <li>
                    <a href="/about" className="text-white">
                      About{" "}
                    </a>
                  </li>

                  <li>
                    <a href="/contact-us" className="text-white">
                      Contact Us{" "}
                    </a>
                  </li>

                  <li>
                    <a href="/services" className="text-white">
                      Explore-Services
                    </a>
                  </li>

                  <li>
                    {" "}
                    <a href="/Careers" className="text-white">
                      Careers
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="/privacy-policy" className="text-white">
                      privacy-policy
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="/terms-conditions" className="text-white">
                      Terms & Conditions
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className="row">
              <div className="col copyrights text-white ">
                <p className="sopyrights text-center">
                  {" "}
                  © All copy rights reserved by L V L GROUPS & ENTERPRISES PVT
                  LTD @2024
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <div>
          <p className="footerp">Version 3.0</p>
        </div>
      </Container>
    </>
  );
}

export default Footer;
