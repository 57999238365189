import React, { useEffect, useRef } from "react";
import { useState } from "react";
// import Typewriter from 'typewriter-effect';
// import Logistics from "../../Images/logistics.webp";
import Navbar from "./../../compounds/AppBar/AppBar";
import "./ConatctUs.css";
import Aos from "aos";
import emailjs from "@emailjs/browser";
import "aos/dist/aos.css";
import Footer from "../../compounds/Footer/Footer";
import Button from "../../compounds/Button/Button";
import { Container, Row, Col } from "react-bootstrap";
import ContactUsForm from "../ContactUsForm/ContactUsForm";
function ContactUs() {
  useEffect(() => {
    Aos.init();
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const formRef = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    var templateParams = {
      subject: "Lead from client",
    };

    emailjs
      .sendForm(
        "service_likith",
        "template_likith",
        formRef.current,
        "4dmRyihC48BHu6snY",
        templateParams
      )
      .then(
        (result) => {
          alert("Message Sent Successfully");
        },
        (error) => {
          console.log(error.text);
          alert("Something went wrong!");
        }
      );
    e.target.reset();
  };

  return (
   
    <div className="contact-us ">
      {/* <Navbar /> */}
      {/* <Button /> */}
      <ContactUsForm />
     
      {/* <Footer /> */}
    </div>
  );
}

export default ContactUs;
