import React from "react";
import "./Member.css";
import Navbar from "../../compounds/AppBar/AppBar";
import Footer from "../../compounds/Footer/Footer";
import Button from "../../compounds/Button/Button";
import { Col, Container, Row } from "react-bootstrap";
import Joinus from "../../pages/JoinUs/JoinUs";
function Member() {
  return (
    <div className="Members">
      {/* <Navbar /> */}
      {/* <Joinus /> */}
      {/* <Button /> */}
     
      <Footer />
    </div>
   
  );
}

export default Member;
