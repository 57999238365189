import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../Images/footer1-logo.png";
// import Logo1 from '../../Images/w.jpeg'
import NavDropdown from "react-bootstrap/NavDropdown";
import { FaPhoneAlt } from "react-icons/fa";
import ausIcon from "../../Images/aus-flag-min.webp";
import indIcon from "../../Images/ind-flag.webp";
import "../AppBar/AppBar.css";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
function AppBar() {
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const handleWhatsAppClick = () => {
    const phoneNumber = "8019328988"; // Replace with the actual phone number
    const message = "Hello!"; // Optional: You can prefill a message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.location.href = url;
  };
  const handleWhatsAppClickchange = () => {
    const phoneNumber = "61406185055"; // Replace with the actual phone number
    const message = "Hello!"; // Optional: You can prefill a message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.location.href = url;
  };
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) { // Change this value to the scroll height you want
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Define transparency and blue color based on scroll position
  const backgroundColor = scrollY > 100;
  const opacity = scrollY > 200 ? 2 : 0.7;
  // style={{ backgroundColor, opacity, padding: '1px', transition: 'background-color 0.6s, opacity 0.3s' }}
  return (
    <div className="row appnav">
    <div className="col-12">
      <Navbar expand="lg"  className={`bg-body-tertiary navbarhead ${scrolled ? 'scrolled' : ''}`}>
        <Container fluid className="header">
          <Navbar.Brand href="/home" className="logo">
            <img src={Logo} loading="lazy" alt="logotype" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="justify-content-between mx-auto" navbarScroll>
              <Nav.Link href="/about">About</Nav.Link>
              <Nav.Link href="/services">Explore Services</Nav.Link>
              <NavDropdown title="Product Services" id="navbarScrollingDropdown">
                <NavDropdown.Item href="/ofc&pkg">Office & Package Supplies</NavDropdown.Item>
                <NavDropdown.Item href="/clothing&apparel">Clothing & Apparel</NavDropdown.Item>
                <NavDropdown.Item href="/rawmaterial">Raw Materials</NavDropdown.Item>
                <NavDropdown.Item href="/toys">Toys</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="IT Services" id="navbarScrollingDropdown">
                <NavDropdown.Item href="/webdesign&application">Web Design & Application</NavDropdown.Item>
                <NavDropdown.Item href="/digitalmedia">Digital Marketing</NavDropdown.Item>
                <NavDropdown.Item href="/crmintegration">CRM Integration</NavDropdown.Item>
                <NavDropdown.Item href="/Bisiness&itconsulting">Business & IT Consulting</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/contact-us">Contact Us</Nav.Link>
            </Nav>
            <Button className="btn btn-light home" variant="light" onClick={handleWhatsAppClickchange}>
              <img src={ausIcon} style={{ width: '20px' }} alt="Australia Icon" /> +61 406185055
            </Button>
            &nbsp;&nbsp;
            <Button className="btn btn-light home" variant="light" onClick={handleWhatsAppClick}>
              <img src={indIcon} style={{ width: '20px' }} alt="India Icon" /> +91 8019328988
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  </div>
  );
}

export default AppBar;

