import React,{useEffect} from 'react'
import './ItSourcingPage.css'
import AppBar from '../../compounds/AppBar/AppBar';
import Footer from '../../compounds/Footer/Footer';



function ItSourcingPage() {
  return (
    <div>
   
  </div>
  )
}

export default ItSourcingPage


