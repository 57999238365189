// src/components/ContactForm.js
import React, { useState } from "react";
import { useEffect } from "react";
import "./ContactForm.css"; // Import your CSS for styling

import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Aos from "aos";
import "aos/dist/aos.css";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NavBar from "../../compounds/AppBar/AppBar";
import Footer from "../../compounds/Footer/Footer";
import Button from "../../compounds/Button/Button";
import location from "../../Images/location1.webp";
import phone from "../../Images/phone-(1).webp";
import email from "../../Images/email.webp";
import star from "../../Images/star.webp";
import star1 from "../../Images/star-(1).webp";
import { ContactFormgeneral } from "../../../src/Services/ApiService";
// import abstract from "../../Images/abstract-lines-landing-page-blue-background-template.webp"
const Contactform = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, resume: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    ContactFormgeneral(formData)
      .then(
        (response) => {
          console.log("Success:", response.data);
          console.log(formData, "formdata");
        },
        setFormData({
          Name: "",
          Email: "",
          Phone: "",
          Message: "",
        })
      )
      .catch((error) => {
        console.error("Error:", error);
      });
    console.log("Form Data Submitted:", formData);
  };

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Container fluid className="contactjudt">
        <Button />
        <Container fluid className="newcontainer  ">
          <Row className="pb-4 pt-4">
            <Col xs={12} sm={12} md={6} lg={6} className="p-5">
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col className="col-lg-12 col-md-12 col-sm-12 col-12">
                    {" "}
                    <div className="p-2">
                      <input
                        type="text"
                        name="Name"
                        placeholder="Name"
                        value={formData.Name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="p-2">
                      <input
                        type="email"
                        name="Email"
                        value={formData.Email}
                        onChange={handleChange}
                        placeholder="Email ID"
                        required
                      />
                    </div>
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-sm-12 col-12">
                    {" "}
                    <div className="p-2">
                      <input
                        type="tel"
                        name="Phone"
                        placeholder="Phone Number"
                        value={formData.Phone}
                        onChange={handleChange}
                        required
                        pattern="[0-9]*"
                        inputMode="numeric"
                      />
                    </div>
                  </Col>
                  <Col className="col-lg-12 col-md-12 col-sm-12 col-12">
                    {" "}
                    <div className="p-2">
                      <textarea
                        name="Message"
                        placeholder="Message"
                        value={formData.Message}
                        style={{
                          border: "1px solid dbd5d5",
                          borderRadius: "5px",
                        }}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </Col>
                </Row>

                <div className="text-center ">
                  <button type="submit" className="btn btn-white contact ">
                    Submit
                  </button>
                </div>
              </form>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} className="p-3 mt-4">
              <h1 className="contactus">
                <img src={star} alt="" loading="lazy" className="star" />
                <img src={star1} alt="" loading="lazy" className="star1" />
                <span className="contactusfor">Contact</span>{" "}
                <span className="uscontact">Us</span>
              </h1>
              <p className="text-white m-0 uscontactformp mt-2">
                For questions, technical assistance, or collaboration
                opportunities via the contact information provided.
              </p>
              <br />

              <ul className="p-0">
                <li className="">
                  <p className="m-0 contactli">
                    {" "}
                    <img
                      src={phone}
                      alt=""
                      loading="lazy"
                      style={{ width: "auto", height: "2rem" }}
                    />{" "}
                    &nbsp;
                    <span>+61 406185055</span>
                  </p>
                </li>
                <li className="">
                  <p className="m-0 contactli">
                    {" "}
                    <img
                      src={email}
                      alt=""
                      loading="lazy"
                      style={{ width: "auto", height: "2rem" }}
                    />{" "}
                    &nbsp;
                    <span>info@lvlgroups.com</span>
                  </p>
                </li>
                <li className="">
                  <p className="m-0 contactli">
                    <img
                      src={location}
                      alt=""
                      loading="lazy"
                      style={{ width: "auto", height: "2rem" }}
                    />{" "}
                    &nbsp;
                    <span>Melbourne, Australia</span>
                  </p>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Contactform;
