import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import ofcpkg from "../../../Images/Clothing (2).webp";
// import technology from "../../../Images/clothing.webp";
// import wholesale from "../../../Images/cloud.webp";
// import Bcons from "../../../Images/cyber.webp";
// import consulting from "../../../Images/it consulting.webp";
// import analyst from "../../../Images/analyst.webp";
// import Web from "../../../Images/web-design-concepts.webp";
import Aos from "aos";
import "aos/dist/aos.css";
import "./Technologys.css";
const Technologys = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Container fluid className="Technology-section">
        <Row></Row>
      </Container>
      <Container>
        <Row className="mt-5">
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={3}
            className="mb-4 Technologysec1 "
            // data-aos="zoom-in"
            // data-aos-delay="500"
            // data-aos-duration="1000"
          >
            <h3 id="sware">Web Solutions</h3>
            {/* <img src={Web} alt="" className="techweb" /> */}
          </Col>
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={9}
            className="mb-4 Technologysec1 "
            // data-aos="zoom-in"
            // data-aos-delay="500"
            // data-aos-duration="1000"
          >
            <h5>
              <span>1</span>. Custom Software Development
            </h5>
            <p>
              {" "}
              Tailor-made software solutions to meet your specific business
              needs.
            </p>
            <h5>
              <span>2</span>. Application Modernization
            </h5>
            <p>
              Update and enhance legacy systems to improve performance and
              functionality.
            </p>
            <h5>
              <span>3</span>. Mobile App Development
            </h5>
            <p>
              Design and develop user-friendly mobile applications for iOS and
              Android platforms.{" "}
            </p>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container>
        <Row className="mt-5">
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={9}
            className="mb-4 Technologysec1 "
            // data-aos="zoom-in"
            // data-aos-delay="500"
            // data-aos-duration="1000"
          >
            <h5>
              <span>1</span>. Cloud Migration
            </h5>
            <p>
              {" "}
              Transition your existing infrastructure to the cloud seamlessly
              with minimal disruption.
            </p>
            <h5>
              <span>2</span>. Cloud Management
            </h5>
            <p>
              Ongoing management and optimization of cloud resources to ensure
              efficiency and cost-effectiveness.
            </p>
            <h5>
              <span>3</span>. Cloud Security
            </h5>
            <p>
              Robust security measures to protect your data and ensure
              compliance with industry standards.
            </p>
          </Col>
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={3}
            className="mb-4 Technologysec1 "
            // data-aos="zoom-in"
            // data-aos-delay="500"
            // data-aos-duration="1000"
          >
            <h3 id="cloudservice">Digital Media</h3>
            {/* <img src={Web} alt="" className="techweb" /> */}
          </Col>
        </Row>
      </Container>
         </>
  );
};

export default Technologys;
