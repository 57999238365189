import axios from "axios";

// require('dotenv').config({
//   path: process.env.NODE_ENV === 'production' ? '.env.production' : '.env.local',
// });


const BASE_URL = process.env.REACT_APP_API_URL;

export const addQuoteDetails = (data) => {
  return axios.post(`${BASE_URL}/addQuoteDetails`, data);
};

export const ContactFormgeneral = (data) => {
  return axios.post(`${BASE_URL}/addContactDetails`, data);
};
export const joinUsDetails = (data) => {
  return axios.post(`${BASE_URL}/JoinUsDetails`, data);
};

export const JobApplicationDetails = (data) => {
  return axios.post(`${BASE_URL}/JobApplicationDetail`, data);
};
