import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import business3 from "../../../Images/customization.webp";
import business1 from "../../../Images/assesment.webp";
import business2 from "../../../Images/implementation.webp";
import business4 from "../../../Images/support.webp"
import ContactUsForm from "../../../pages/ContactUsForm/ContactUsForm";
import CustomModel from "../../../compounds/Model/Model";
import Buttons from "../../../compounds/Button/Button";
import NavBar from "../../../compounds/AppBar/AppBar";
import Footer from "../../../compounds/Footer/Footer";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import "./BusnessIt.css";

const BusnessIt = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the desired path
    navigate('/contact-us');
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
    {/* <NavBar /> */}
    {/* <Buttons /> */}
      <Container fluid className="businessit container-fluid ">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="headbg">
            <h1 className=" text-center text-white mt-5">
              Business & IT Consulting{" "}
            </h1>
            <br />
            <p className="text-center">
              Welcome to L V L GROUPS & ENTERPRISES PVT LTD, your trusted
              partner in Business and IT Consulting.
              <br />
              Our mission is to deliver exceptional Business and IT Consulting
              services that help organizations achieve their strategic goals.
            </p>
            <p className="text-center">
              
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="mt-5">
           
            <h2 className="business text-center">
              Transform your business with our expert consulting – where
              innovation meets excellence!
            </h2>
            <p className="">
              At L V L GROUPS AND ENTERPRISES PVT LTD, we offer Business and IT
              Consulting services designed to help your organization thrive in a
              competitive landscape. Our focus is on aligning technology with
              your business goals to drive efficiency, innovation, and growth.
              Here’s how we do it.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-4 section-3colw ">
          <Col xs={12} sm={12} md={12} lg={12}>
            {/* <div className="is-stickeys"> */}
            <h2 className="Webh2 text-center m-5 ">
              Here’s a look at Our Business & IT Consulting:
            </h2>
            {/* </div> */}
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            <Row >
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
                
              >
                <h2 className="Webh2 text-center">Assessment</h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  We begin with a comprehensive analysis of your current IT
                  infrastructure and business processes, identifying strengths,
                  weaknesses, and opportunities for improvement.
                </p>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
               
              >
                <div className="image-container">
                  <img
                    src={business1}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
               
              >
                <div className="image-container">
                  <img
                    src={business3}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow "
                  />
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
                
              >
                <h2 className="Webh2 text-center">Customization</h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  Based on our assessment, we develop tailored IT strategies
                  that address your specific needs and align with your long-term
                  business goals.
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
               
              >
                <h2 className="Webh2 text-center">Implementation</h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  Our experienced consultants work closely with your team to
                  implement the solutions, ensuring a smooth transition and
                  minimal disruption to your operations.
                </p>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
               
              >
                <div className="image-container">
                  <img
                    src={business2}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
               
              >
                <div className="image-container">
                  <img
                    src={business4}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow "
                  />
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
                
              >
                <h2 className="Webh2 text-center">Ongoing Support</h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  We provide ongoing support and optimization services to ensure
                  your systems continue to deliver value as your business
                  evolves.
                </p>
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            
          >
            {/* border-bottom: thick double #32a1ce; */}
            <div className="is-stickey">
              <h2 className="webdesign mt-3">Why Choose Us?</h2>
              <p>
                <span className="webdesign">Expertise:</span> Our team combines
                years of experience in web design and development, ensuring your
                site not only looks great but also performs optimally.
              </p>
              <p>
                <span className="webdesign">Customization:</span> We tailor each
                project to fit your unique business goals, ensuring that your
                website reflects your brand’s identity and meets your specific
                needs.
              </p>
              <p>
                <span className="webdesign">Innovation: </span>We stay ahead of
                the latest trends and technologies to provide modern solutions
                that keep you competitive.
              </p>
              <p>
                <span className="webdesign">Support:</span> From initial
                consultation to ongoing maintenance, we’re here to support you
                every step of the way.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <br />
      <Container fluid className="p-5">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="businessitborder">
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} className="p-5 bordercent">
                <h3 className="text-center webdesign mt-3">BUSINESS & IT CONSULTING</h3>
                <p className="p-0">
                  With L V L GROUPS AND ENTERPRISES PVT LTD as your consulting
                  partner, you can be confident that your IT infrastructure and
                  business strategies are fully aligned and optimized for future
                  growth. Our comprehensive services and dedicated support
                  ensure that your organization is not just prepared for today’s
                  challenges but is also well-equipped to seize tomorrow’s
                  opportunities.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="bordercent">
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6} className="bordercent">
                    <h3 className="text-center webdesign">Expertise</h3>
                    <p className="p-0">
                      Our team comprises seasoned consultants with deep industry
                      expertise, ensuring we deliver solutions that are both
                      innovative and practical.
                    </p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} className="bordercent">
                    <h3 className="text-center webdesign">Tailored Solutions</h3>
                    <p className="p-0">
                      We don’t believe in one-size-fits-all solutions. Every
                      strategy we develop is tailored to your specific business
                      needs and goals.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6} className="bordercent">
                    <h3 className="text-center webdesign">Proven Results</h3>
                    <p className="p-0">
                      Our track record speaks for itself—clients across
                      industries have achieved significant improvements in
                      efficiency, cost savings, and business growth through our
                      consulting services.
                    </p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} className="bordercent">
                    <h3 className=" text-center webdesign">Customer-Centric Approach</h3>
                    <p className="p-0">
                      We prioritize your success, working closely with you at
                      every stage to ensure our solutions meet your expectations
                      and deliver lasting value.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={3} lg={3} className="bordercent">
                <h3 className="text-center webdesign">IT Strategy Development</h3>
                <p className="p-0">
                  Crafting long-term technology roadmaps that support your
                  business objectives.
                </p>
                <h4 className="text-center webdesign">Benefits</h4>
                <p className="p-0">
                  Alignment of IT with business goals, enhanced efficiency, and
                  scalability.
                </p>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3} className="bordercent">
                <h3 className="text-center webdesign">Digital Transformation</h3>
                <p className="p-0">
                  Implementing innovative technologies to drive growth and
                  competitiveness.
                </p>
                <h4 className="text-center webdesign">Benefits</h4>
                <p className="p-0">
                  Increased agility, improved customer experience, and
                  future-proofing your business.
                </p>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3} className="bordercent">
                <h3 className="text-center webdesign">Product Sourcing Optimization</h3>
                <p className="p-0">
                  Streamlining sourcing processes and enhancing supply chain
                  management
                </p>
                <h4 className="text-center webdesign">Benefits</h4>
                <p className="p-0">
                  Cost reduction, improved supplier relationships, and faster
                  time-to-market.
                </p>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3} className="bordercent">
                <h3 className="text-center webdesign">Cybersecurity Solutions</h3>
                <p className="p-0">
                  Protecting your IT infrastructure and data from threats.
                </p>
                <h4 className="text-center webdesign">Benefits</h4>
                <p className="p-0">
                  Enhanced security, compliance, and risk management.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="text-center">
            <h1 className="text-center webdesign p-3">Why We Are the Best to Approach</h1>
            <p>
              <span className="webdesign" style={{ fontSize: "20px", fontWeight: "600" }}>
                {" "}
                Comprehensive Expertise:
              </span>
              We possess a unique blend of IT and business consulting expertise
              enabling us to offer holistic solutions that address all facets of
              your business.
            </p>
            <p>
              <span className="webdesign" style={{ fontSize: "20px", fontWeight: "600" }}>
                {" "}
                Strategic Partnership:
              </span>
              We view our relationship with clients as a long-term partnership
              committed to helping you achieve your goals and grow your
              business..
            </p>
            <p>
              <span  className="webdesign"style={{ fontSize: "20px", fontWeight: "600" }}>
                {" "}
                Innovative Solutions:
              </span>
              We stay ahead of industry trends ensuring that the solutions we
              offer are cutting-edge and positioned to give you a competitive
              advantage.
            </p>
            <p>
              <span className="webdesign" style={{ fontSize: "20px", fontWeight: "600" }}>
                {" "}
                Client-Centric Service:
              </span>
              Our approach is deeply client-focused ensuring that your needs are
              always at the forefront of our service delivery.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className="our-team-assistwebssubscribe">
          <Col xs={12} sm={12} md={8} lg={8}>
            <h2 className="text-center text-white p-3">
              Grow Your Business With L V L groups
            </h2>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} className="text-center">
            <Button
              className="btn btn-primary classifybtn mt-3"
              onClick={handleClick}
            >
              {" "}
              Contact Us
            </Button> 
           
          </Col>
        </Row>
      </Container>
      {/* <Footer /> */}
    </>
  );
  
};

export default BusnessIt;
