import React, { useEffect } from "react";
import "./TextContainer.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Col, Container, Row } from "react-bootstrap";

function TextContainer() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <h2 className="home-txt-container-txt mt-4">
            Discover Top-Notch Sourcing with L V L GROUPS & ENTERPRISES PVT LTD
          </h2>
          <p className="home-txt-container-sub-txt m-2">
            We specialize in IT and Product Sourcing, combined with Business
            Consulting, to connect you with top resources. By sourcing
            high-quality products directly from manufacturers, suppliers, and
            vendors, We help you{" "}
            <span className="highlettext">Save you up to 30%. </span>Our
            advanced inventory management solution ensures an efficient
            inventory cycle for your warehouse, store or office. Additionally,
            we offer private labeling services to customize products with your
            branding.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default TextContainer;

