import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { JobApplicationDetails } from "../../../Services/ApiService";
import "./CareerContact.css";
const CareerContact = () => {
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneNumber: "",
    Department: "",
    FileUpload: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({
      ...formData,
      FileUpload: file,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("FirstName", formData.FirstName);
    formDataToSend.append("LastName", formData.LastName);
    formDataToSend.append("Email", formData.Email);
    formDataToSend.append("PhoneNumber", formData.PhoneNumber);
    formDataToSend.append("Department", formData.Department);
    formDataToSend.append("FileUpload", formData.FileUpload);

    JobApplicationDetails(formData)
      .then(
        (response) => {
          console.log("Success:", response.data);
          console.log(formData, "formdata");
          // Handle success (e.g., show a success message, reset form, etc.)
        },
        setFormData({
          FirstName: "",
          LastName: "",
          Email: "",
          PhoneNumber: "",
          Department: "",
          FileUpload: "",
        })
      )
      .catch((error) => {
        console.error("Error:", error);
        // Handle error (e.g., show an error message)
      });
  };
  return (
    <Container>
      <Row>
        <Col className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="input-container">
            <h2>Job Application Form</h2>

            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="FirstName"
                placeholder="First Name"
                value={formData.FirstName}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="LastName"
                placeholder="Last Name"
                value={formData.LastName}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                name="Email"
                placeholder="Email"
                value={formData.Email}
                onChange={handleChange}
                required
              />
              <input
                type="tel"
                name="PhoneNumber"
                placeholder="Phone Number"
                value={formData.PhoneNumber}
                onChange={handleChange}
                required
              />
              <select
                name="Department"
                value={formData.Department}
                onChange={handleChange}
                required
              >
                <option value="">Select a Department</option>
                <option value="Digital Marketing">Digital Marketing</option>
                <option value="Sales">Sales</option>
                <option value="CRM">CRM</option>
                <option value="Technology">Technology</option>
              </select>
              <input
                type="file"
                name="resume"
                accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CareerContact;
