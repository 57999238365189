import React, { useEffect,useState } from "react";
// import ofcpkg from "../../../Images/ofc-pkg.webp";
// import wholesale from "../../../Images/office&package-side.jpeg";
import Bcons from "../../../Images/B-cons-min.webp";
import circle3 from "../../../Images/Mask-group-1.webp";
import circle1 from "../../../Images/Mask-group-2.webp";
import circle2 from "../../../Images/Mask-group-3.webp";
import circle4 from "../../../Images/Mask-group.webp";
import VideoPlayer from "../../../assets/LVL OFFICE AND PACAKEGE SUPPLIES.mp4";
import officebg from "./../../../Images/office&package-bg1.webp"
import ContactUsForm from "../../../pages/ContactUsForm/ContactUsForm";
import CustomModel from "../../../compounds/Model/Model";
import { IoIosArrowForward } from "react-icons/io";
import "./OfficePackage.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Container, Row, Col,Button } from "react-bootstrap";
import { background } from "@chakra-ui/react";
const OfficePackage = () => {
  const [showModel, setShowModel] = useState(false);
  const handleClose = () => setShowModel(false);
  const handleOpen = () => setShowModel(true);
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Container fluid className="Office-section p-0">
        <Row>
          <Col>

          <img
              src="{officebg}"
              alt=""
              loading="lazy"
              className="officepackag mt-5"
              srcset={`${officebg} 300w, ${officebg} 768w, ${officebg} 1280w`}
              sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
            />
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="mt-5 ">
            <h1 className="text-center office">Office and Package Supplies</h1>
            <p className="text-center">
            Welcome to L V L GROUPS & ENTERPRISES PVT LTD – your source for premium office and packaging supplies.
            </p>
            <p className="text-center">
              Our mission is to deliver superior office and packaging supplies,
              exceeding expectations through innovation, sustainability, and
              customer-centric excellence.
            </p>
            <h2 className="officeh2 text-center">
              Transforming efficiency with every package, we don't just meet
              expectations – we shatter them.
            </h2>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12}>
            {/* <div id="wrapper "> */}
            <br />
            <h4 className="text-center   titlesofficepcs" id="title">
              Our Services
            </h4>

            <p>
              We specialize in sourcing a wide range of office and packaging
              supplies tailored to meet your business needs. With an extensive
              network of suppliers, we ensure high-quality products at
              competitive prices, covering everything from standard office
              essentials to specialized packaging materials. Our dedicated team
              works closely with clients to deliver customized solutions that
              enhance efficiency and productivity, ensuring timely delivery and
              consistent supply.
            </p>
            {/* </div>/ */}
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5} className="">
            <div className="image-containeroff mt-5">
              <video autoPlay muted loop className="video image-with-shadowoff">
                <source src={VideoPlayer} type="video/mp4" />
                {/* Your browser does not support the video tag. */}
              </video>
            </div>
          </Col>
          <Col xs={12} sm={12} md={7} lg={7} className=" ">
            {/* <div className="wrapper1">
              <div id="wrapper "> */}

            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <h4 className="text-center   titlesofficepcs" id="title2">
                  Our Process
                </h4>

                <p>
                  Our streamlined sourcing process ensures you receive the best
                  products at the best prices. We handle everything from
                  assessing your needs to negotiating with suppliers for optimal
                  deals.
                </p>
              </Col>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} >
                  <h4 className="ourprocessh4">
                    <img
                      src={circle1}
                      alt=""
                      loading="lazy"
                      style={{ width: "auto", height: "25px" }}
                    />{" "}
                    Need Assessments:{" "}
                  </h4>
                  <p className="description-text">
                    Detailed consultations to identify specific requirements.
                  </p>
                  <h4 className="ourprocessh4">
                    <img
                      src={circle2}
                      alt=""
                      loading="lazy"
                      style={{ width: "auto", height: "25px" }}
                    />{" "}
                    Supplier Selection:{" "}
                  </h4>
                  <p className="description-text">
                    Choosing the best suppliers based on capability and
                    reliability.
                  </p>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <h4 className="ourprocessh4">
                    <img
                      src={circle3}
                      alt=""
                      loading="lazy"
                      style={{ width: "auto", height: "25px" }}
                    />{" "}
                    Quotation & Negotiation:{" "}
                  </h4>
                  <p className="description-text">
                    Securing competitive prices and favorable terms.
                  </p>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
      <br />
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <h4 className="text-center  titlesofficepcs" id="title1">
              Supplier Network
            </h4>

            <p>
              Our robust supplier network spans globally, giving us access to
              top-quality products at unbeatable prices. We collaborate with
              trusted manufacturers, wholesalers, and distributors to ensure a
              diverse and reliable supply chain.
            </p>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h4 className="ourprocessh4"> 
                  <img
                    src={circle1}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Global Reach:{" "}
                </h4>
                <p className="description-text">
                  Access to suppliers worldwide for a wide range of products.
                </p>
                <h4 className="ourprocessh4">
                  <img
                    src={circle2}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Trusted Partnerships:{" "}
                </h4>
                <p className="description-text">
                  Collaborations with reputable and reliable suppliers.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h4 className="ourprocessh4">
                  <img
                    src={circle3}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Comprehensive Selection:
                </h4>
                <p className="description-text">
                  Diverse product offerings to meet various business needs.
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <h4 className="text-center   titlesofficepcs" id="title1">
              Quality Assurance
            </h4>

            <p>
              Our commitment to quality ensures that every product meets the
              highest standards. We implement rigorous quality control measures,
              working only with suppliers who adhere to stringent criteria.
            </p>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} >
                <h4 className="ourprocessh4">
                  <img
                    src={circle1}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Stringent Inspections:{" "}
                </h4>
                <p className="description-text">
                  Thorough product inspections and assessments.
                </p>
                <h4 className="ourprocessh4">
                  <img
                    src={circle2}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Supplier Vetting:{" "}
                </h4>
                <p className="description-text">
                  Only partnering with suppliers who meet high-quality
                  standards.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h4 className="ourprocessh4">
                  <img
                    src={circle3}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Continuous Monitoring:{" "}
                </h4>
                <p className="description-text">
                  Regular evaluations to maintain consistent product quality.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <div className="our-team-assistoffic">
          <h2 className="business text-center">Why Choose Us?</h2>
          <p className="">
            <img
              src={circle1}
              alt=""
              loading="lazy"
              style={{ width: "auto", height: "30px" }}
            />{" "}
            We source the highest quality office and packaging supplies while
            maintaining competitive pricing, giving you the best value for your
            budget.
          </p>
          <p className="">
            <img
              src={circle2}
              alt=""
              loading="lazy"
              style={{ width: "auto", height: "30px" }}
            />{" "}
            Our extensive network ensures a comprehensive selection of products,
            each undergoing stringent quality checks for reliability and
            performance.
          </p>
          <p className="">
            <img
              src={circle3}
              alt=""
              loading="lazy"
              style={{ width: "auto", height: "30px" }}
            />{" "}
            Our expert team provides tailored support and ensures prompt,
            accurate deliveries, making your procurement process efficient and
            stress-free.
          </p>
        </div>
      </Container>
      <Container fluid>
        <Row className="our-team-assistwebssubscribe">
          <Col xs={12} sm={12} md={9} lg={9}>
            <h2 className="text-center text-white p-2 mt-3">
              Grow Your Business With L V L groups
            </h2>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3} className="text-center">
          <Button
                  className="hero-button btn btn-light mt-2" s
                  variant="light"
                  onClick={handleOpen}
                  
                >
                  Request Quotation <IoIosArrowForward className="arrow-icon" />
                </Button>
                <CustomModel
                  show={showModel}
                  handleClose={handleClose}
                  title="Request Quotation"
                  modalClassName="modeltop"
                  dialogClassName="custom-modal-width"
                  headerStyle={{ padding: "25px" }}
                  buttonStyle={{
                    position: "absolute",
                    top: "15px",
                    right: "15px",
                    padding: "0.8rem",
                  }}
                >
                  <ContactUsForm />
                </CustomModel>
            
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OfficePackage;
