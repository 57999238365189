import React, { useEffect, useState } from "react";
import { addQuoteDetails } from "../../../src/Services/ApiService";
import "./ContactUsForm.css";

const ContactUsForm = () => {
  useEffect(() => {
    // Initialization code if needed
  }, []);
  const [isVisible, setIsVisible] = useState(true);

  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    ShippingDestination: "",
    Manufacturing: "",
    BusinessTypeName: "",
    ProductType: "",
    ProductQty: "",
    ProductImage: null,
    Notes: "",
    HearAboutUs: {
      Instagram: false,
      Facebook: false,
      Linkedin: false,
      Google: false,
      WordOfMouth: false,
      Other: false,
    },
  });

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    if (type === "checkbox") {
      setFormData({
        ...formData,
        HearAboutUs: {
          ...formData.HearAboutUs,
          [name]: checked,
        },
      });
    } else if (type === "file") {
      setFormData({
        ...formData,
        ProductImage: files[0], // Handle file input
      });
    } else if (type === "radio") {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    if (!form.checkValidity()) {
      form.reportValidity(); // Trigger built-in validation messages
      return; // Stop the function if the form is invalid
    }

    const selectedFields = Object.keys(formData.HearAboutUs)
      .filter((source) => formData.HearAboutUs[source])
      .join(", ");
    const formDataWithSelectedFields = {
      ...formData,
      HearAboutUs: selectedFields,
    };

    addQuoteDetails(formDataWithSelectedFields)
      .then((response) => {
        console.log("Success:", response.data);
        console.log(formDataWithSelectedFields, "formdata");
        // Handle success (e.g., show a success message, reset form, etc.)
       
      } ,
      setFormData({
        Name: "",
    Email: "",
    Phone: "",
    ShippingDestination: "",
    Manufacturing: "",
    BusinessTypeName: "",
    ProductType: "",
    ProductQty: "",
    ProductImage: null,
    Notes: "",
    HearAboutUs: {
      Instagram: false,
      Facebook: false,
      Linkedin: false,
      Google: false,
      WordOfMouth: false,
      Other: false,
    },
    
      }),
      setIsVisible(false),

    )
      .catch((error) => {
        console.error("Error:", error);
       
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>
          Name <span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="text"
          name="Name"
          placeholder="Name"
          value={formData.Name}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>
          Email <span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="email"
          name="Email"
          placeholder="Email"
          value={formData.Email}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>
          Phone Number <span style={{ color: "red" }}>*</span>
        </label>

        {/* <label>Phone Number:</label> */}
        <input
          type="tel"
          name="Phone"
          className="phonenumber"
          placeholder="Phone Number"
          value={formData.Phone}
          onChange={handleChange}
          required
          pattern="[0-9]*"
          inputMode="numeric"
        />
      </div>
      <div>
        <label>
          Type of product <span style={{ color: "red" }}>*</span>
        </label>
        <textarea
          name="ProductType"
          placeholder="Type of Product"
          value={formData.ProductType}
          className="p-2"
          onChange={handleChange}
          style={{ border: "1px solid dbd5d5", borderRadius: "5px" }}
          required
        ></textarea>
      </div>
      <div>
        <label>
          Approx product Qty <span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="number"
          name="ProductQty"
          placeholder="Approx product qty"
          value={formData.ProductQty}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>
          What best describes you and your business?{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <select
          name="BusinessTypeName"
          value={formData.BusinessTypeName}
          onChange={handleChange}
          required
        >
          <option value="" disabled>
            Select an option
          </option>
          <option value="Start-Up $0 - 100K Revenue">
            Start-Up $0 - 100K Revenue
          </option>
          <option value="Small Sized Business $100K - $1M Revenue">
            Small Sized Business $100K - $1M Revenue
          </option>
          <option value="Medium Sized Business $1M - $5M Revenue">
            Medium Sized Business $1M - $5M Revenue
          </option>
          <option value="Large Sized Business $5M+ Revenue">
            Large Sized Business $5M+ Revenue
          </option>
        </select>
      </div>
      <div>
        <label>
          Are you selling this product already?{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <div style={{ display: "flex" }}>
          <input
            type="radio"
            name="Manufacturing"
            value="Yes"
            checked={formData.Manufacturing === "Yes"}
            onChange={handleChange}
          />{" "}
          &nbsp;
          <label>Yes</label>
        </div>
        <div style={{ display: "flex" }}>
          <input
            type="radio"
            name="Manufacturing"
            value="No"
            checked={formData.Manufacturing === "No"}
            onChange={handleChange}
          />{" "}
          &nbsp;
          <label>No</label>
        </div>
      </div>
      <div>
        <label>
          How did you hear about us? <span style={{ color: "red" }}>*</span>
        </label>
        <div className="checkbox-group">
          {Object.keys(formData.HearAboutUs).map((source) => (
            <div key={source}>
              <input
                type="checkbox"
                name={source}
                checked={formData.HearAboutUs[source]}
                onChange={handleChange}
              />
              <label>{source}</label>
            </div>
          ))}
        </div>
      </div>
      <div>
        <label>
          Shipping Destination <span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="text"
          name="ShippingDestination"
          placeholder="Shipping Destination"
          value={formData.ShippingDestination}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Other Note</label>
        <textarea
          name="Notes"
          placeholder="Other Notes"
          value={formData.Notes}
          className="p-1"
          style={{ border: "1px solid dbd5d5", borderRadius: "5px" }}
          onChange={handleChange}
        ></textarea>
      </div>
      <div>
        <label htmlFor="ProductImage">Image of products</label>
        <input
          type="file"
          name="ProductImage"
          accept="image/*"
          onChange={handleChange}
          // required
        />
      </div>
      <button type="submit" className=" btnbtn-white" >
        Submit
      </button>
    </form>
  );
};

export default ContactUsForm;
