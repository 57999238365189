import React, { useState } from "react";
import CareerContact from "../Careers/Career-Contact/CareerContact";
import NavBar from "../../compounds/AppBar/AppBar";
import Footer from "../../compounds/Footer/Footer";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
const TechJd = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {/* <NavBar /> */}
      <Container fluid className="Techjs">
        <Container className="mt-5 p-0">
          <Row className="mt-5  p-4">
            <Col className="mt-5">
              <h3 className=" mt-5"> UI/UX Designer</h3>
              <br />
              <p>
                {" "}
                <span
                  className="seospan"
                  style={{ fontSize: "22px", fontWeight: "500" }}
                >
                  {" "}
                  Position:
                </span>{" "}
                UI/UX Designer
              </p>
              <p>
                {" "}
                <span
                  className="seospan"
                  style={{ fontSize: "22px", fontWeight: "500" }}
                >
                  {" "}
                  Experiance:
                </span>{" "}
                0 - 3 Years{" "}
              </p>
              <p>
                {" "}
                <span
                  className="seospan"
                  style={{ fontSize: "22px", fontWeight: "500" }}
                >
                  {" "}
                  Location:
                </span>{" "}
                Hyderabad(HYD){" "}
              </p>
              <p>
                {" "}
                <span
                  className="seospan"
                  style={{ fontSize: "22px", fontWeight: "500" }}
                >
                  {" "}
                  Type:
                </span>{" "}
                Full-Time
              </p>
              <br />
              <h3>Job Overview:</h3>
              <p>
                {" "}
                LVL Enterprises is looking for a creative and detail-oriented
                UI/UX Designer to create exceptional user experiences for our
                digital products. The ideal candidate will be skilled in
                designing intuitive and aesthetically pleasing interfaces that
                align with our brand and business goals.
              </p>

              <h3>Key Responsibilities:</h3>

              <p>
                Conduct user research and gather feedback to inform design
                decisions and improve user experiences.
              </p>
              <p>
                Create wireframes, prototypes, and high-fidelity mockups for web
                and mobile applications.
              </p>
              <p>
                Collaborate with cross-functional teams, including developers
                and product managers, to ensure design feasibility and alignment
                with project objectives.
              </p>
              <p>
                Develop and maintain design systems and style guides to ensure
                consistency across products.
              </p>
              <p>
                Analyze user behavior and feedback to make data-driven design
                improvements.
              </p>
              <p>Stay updated on design trends, tools, and best practices.</p>

              <h3>Qualifications:</h3>

              <p>
                Proven experience in SEO, with a strong track record of
                improving search engine rankings and driving traffic.Proven
                experience as a UI/UX Designer with a strong portfolio of design
                projects.
              </p>
              <p>
                Proficiency in design and prototyping tools (e.g., Adobe XD,
                Figma, Sketch).
              </p>
              <p>
                Understanding of user-centered design principles and best
                practices.
              </p>
              <p>Strong problem-solving skills and attention to detail.</p>
              <p>Excellent communication and collaboration skills.</p>
              <p>
                Bachelor’s degree in Design, Human-Computer Interaction, or a
                related field is preferred.
              </p>

              <a
                onClick={handleShow}
                // target="_blank"
                style={{
                  fontWeight: "700",
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#0e4cae",
                }}
              >
                <Button className="btnjob btn-primary"> Apply Here.</Button>{" "}
              </a>

              <br />

              <br />
            </Col>
          </Row>
        </Container>
        <Container>
          <Modal show={show} onHide={handleClose} centered className="modeltop">
            <Modal.Header style={{ padding: "25px" }}>
              {/* <Modal.Title>selected jd</Modal.Title> */}
              <Button
                variant="close"
                onClick={handleClose}
                style={{
                  position: "absolute",
                  top: "15px",
                  right: "15px",
                  padding: "0.8rem",
                }}
              ></Button>
            </Modal.Header>
            <div className="col-12 p-3 ">
              <CareerContact />
            </div>
            {/* <Modal.Footer> */}
            {/* <Button className="btn btn-primary" onClick={handleClose}>
            Close
          </Button> */}
            {/* </Modal.Footer> */}
          </Modal>
        </Container>
      </Container>
      {/* <Footer className="p-0" /> */}
    </>
  );
};

export default TechJd;
