import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoadingSpinner from "../src/LoadingSpinner/LoadingSpinner";
import Home from "./pages/Home/Home";
import "./App.css";

import ContactUs from "./pages/ContactUs/ContactUs";
import Services from "./pages/Services/Services";
import Member from "./pages/Become Member/Member";
import TandC from "./pages/Terms&Conditions/TandC";
import PolicyPrivacy from "./pages/PolicyPrivacy/PolicyPrivacy";
import OfcandPkg from "./pages/ProductSourcingPages/OfficeSupplies/OfcandPkg";
import ClothingandApparel from "./pages/ProductSourcingPages/Clothing&Apparel/ClothingandApparel";
import Technology from "./pages/ProductSourcingPages/Technology/Technology";
import RawMaterial from "./pages/ProductSourcingPages/RawMaterial/RawMaterial";
import Toys from "./pages/ProductSourcingPages/Toys/Toys";
import ProductSourcing from "./pages/ProductSourcing/ProductSourcing";
import ItSourcingPage from "./pages/ItSourcingPage/ItSourcingPage";
import GetStarted from "./pages/GetStarted/GetStarted";
import PortfolioDoc from "./pages/PortfolioDoc/PortfolioDoc";
import Web from "./pages/ProductSourcingPages/WebApplication/Web";
import Crm from "./pages/ProductSourcingPages/Crm/Crm";
import Digital from "./pages/ProductSourcingPages/Digitalmedia/Digital";
import BusnessIt from "./pages/ProductSourcingPages/BusinessIt/BusnessIt";
import Error from "./compounds/Error404/Error";
import Careers from "./pages/Careers/Careers";
import TechJd from "./pages/Jobdescriptions/TechJd";
import SeoJd from "./pages/Jobdescriptions/SeoJd";
import SalesJd from "./pages/Jobdescriptions/SalesJd";
import DigitalmarketingJd from "./pages/Jobdescriptions/DigitalmarketingJd";
import Contactform from "./compounds/ContactForm/ContactForm";
import About from "./pages/About/About";

const App = () => {
  const Header = lazy(() => import("./compounds/AppBar/AppBar"));
  const Footer = lazy(() => import("./compounds/Footer/Footer"));
  const Button = lazy(() => import("./compounds/Button/Button"));
  const Home = lazy(() => import("./pages/Home/Home"));
  const About = lazy(() => import("./pages/About/About"));
  const ContactUs = lazy(() => import("./pages/ContactUs/ContactUs"));
  const Services = lazy(() => import("./pages/Services/Services"));
  const Member = lazy(() => import("./pages/Become Member/Member"));
  const TandC = lazy(() => import("./pages/Terms&Conditions/TandC"));
  const PolicyPrivacy = lazy(() =>
    import("./pages/PolicyPrivacy/PolicyPrivacy")
  );
  const ItSourcingPage = lazy(() =>
    import("./pages/ItSourcingPage/ItSourcingPage")
  );
  const ProductSourcing = lazy(() =>
    import("./pages/ProductSourcing/ProductSourcing")
  );
  const OfcandPkg = lazy(() =>
    import("./pages/ProductSourcingPages/OfficeSupplies/OfcandPkg")
  );
  const ClothingandApparel = lazy(() =>
    import("./pages/ProductSourcingPages/Clothing&Apparel/ClothingandApparel")
  );
  const Technology = lazy(() =>
    import("./pages/ProductSourcingPages/Technology/Technology")
  );
  const RawMaterial = lazy(() =>
    import("./pages/ProductSourcingPages/RawMaterial/RawMaterial")
  );
  const Toys = lazy(() => import("./pages/ProductSourcingPages/Toys/Toys"));
  const GetStarted = lazy(() => import("./pages/GetStarted/GetStarted"));
  const PortfolioDoc = lazy(() => import("./pages/PortfolioDoc/PortfolioDoc"));
  const Web = lazy(() =>
    import("./pages/ProductSourcingPages/WebApplication/Web")
  );
  const Crm = lazy(() => import("./pages/ProductSourcingPages/Crm/Crm"));
  const Digital = lazy(() =>
    import("./pages/ProductSourcingPages/Digitalmedia/Digital")
  );
  const BusnessIt = lazy(() =>
    import("./pages/ProductSourcingPages/BusinessIt/BusnessIt")
  );
  const Careers = lazy(() => import("./pages/Careers/Careers"));
  const TechJd = lazy(() => import("./pages/Jobdescriptions/TechJd"));
  const SeoJd = lazy(() => import("./pages/Jobdescriptions/SeoJd"));
  const SalesJd = lazy(() => import("./pages/Jobdescriptions/SalesJd"));
  const DigitalmarketingJd = lazy(() =>
    import("./pages/Jobdescriptions/DigitalmarketingJd")
  );
  const Contactform = lazy(() => import("./compounds/ContactForm/ContactForm"));
  const Error = lazy(() => import("./compounds/Error404/Error"));
  return (
    <Router>
      
      <Suspense fallback={<LoadingSpinner />}>
        <Header />
        <Button />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/membership" element={<Member />} />
          <Route path="/terms&conditions" element={<TandC />} />
          <Route path="/privacy&policy" element={<PolicyPrivacy />} />
          <Route path="/ItSourcingPage" element={<ItSourcingPage />} />
          <Route path="/ProductSourcing" element={<ProductSourcing />} />
          <Route path="/ofc&pkg" element={<OfcandPkg />} />
          <Route path="/clothing&apparel" element={<ClothingandApparel />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/rawmaterial" element={<RawMaterial />} />
          <Route path="/toys" element={<Toys />} />
          <Route path="/getstarted" element={<GetStarted />} />
          <Route path="/portfolio" element={<PortfolioDoc />} />
          <Route path="/webdesign&application" element={<Web />} />
          <Route path="/crmintegration" element={<Crm />} />
          <Route path="/digitalmedia" element={<Digital />} />
          <Route path="/Bisiness&itconsulting" element={<BusnessIt />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/techjs" element={<TechJd />} />
          <Route path="/seojs" element={<SeoJd />} />
          <Route path="/salesjs" element={<SalesJd />} />
          <Route path="/digitalmarketingjs" element={<DigitalmarketingJd />} />
          <Route path="/privacy-policy" element={<PolicyPrivacy />} />
          <Route path="/terms-conditions" element={<TandC />} />
          <Route path="/contact-us" element={<Contactform />} />
          <Route path="*" element={<Error />} />

          {/* <Route path="/login" element={<LogIn/>} />
        <Route path="/signup" element={<Signup/>} /> */}
        </Routes>
        <Footer />
      </Suspense>
    </Router>
  );
};

export default App;
