import React from "react";
import "./TandC.css";
import NavBar from "../../compounds/AppBar/AppBar";
import Footer from "../../compounds/Footer/Footer";
import { Col, Container, Row } from "react-bootstrap";

function TandC() {
  return (
    <>
      {/* <NavBar /> */}
      <Container  className="mt-5">
        <Row className="">
          <Col className="mt-5">
            {/* <div className="mt-5"><h1>Terms and Conditions</h1></div> */}
           
              <h1 className="text-center termsandcondi p-5">Terms & Conditions </h1>

              <h4 className="tandh4">1. Acceptance of Terms </h4>
              <p>
                By accessing and using L V L GROUPS & ENTERPRISES PVT LTD , you
                agree to comply with and be bound by these Terms and Conditions.
                If you do not agree to these terms, you must not use the
                Website.
              </p>
              <h4 className="tandh4">2. Use of the Website </h4>
              <p>
                You may use the Website for lawful purposes only. You agree not
                to use the Website in any way that could damage, disable,
                overburden, or impair the Website or interfere with any other
                party's use and enjoyment of the Website.
              </p>
              <h4 className="tandh4">3. Intellectual Property Rights </h4>
              <p>
                All content on this Website, including but not limited to text,
                graphics, logos, images, and software, is the property of L V L
                GROUPS & ENTERPRISES PVT LTD or its content suppliers and is
                protected by applicable intellectual property laws. Unauthorized
                use of the content is strictly prohibited.
              </p>
              <h4 className="tandh4">4. User-Generated Content </h4>
              <p>
                If you submit any content to the Website, including but not
                limited to comments, reviews, or other communications, you grant
                L V L GROUPS & ENTERPRISES PVT LTD a non-exclusive,
                royalty-free, perpetual, and worldwide license to use,
                reproduce, modify, and distribute such content in any medium.
                You warrant that any content you submit does not infringe on the
                rights of any third party.{" "}
              </p>
              <h4 className="tandh4">5. Privacy Policy </h4>
              <p>
                Your use of the Website is also governed by our Privacy Policy,
                which can be found{" "}
                <a href="/privacy-policy" className="privacy" style={{textDecoration:"underline !important"}}>
                  {" "}
                  Privacy Policy
                </a>
                . Please review the Privacy Policy to understand our practices.{" "}
              </p>
              <h4 className="tandh4">6. Links to Third-Party Sites </h4>
              <p>
                The Website may contain links to third-party websites. These
                links are provided for your convenience, and L V L GROUPS &
                ENTERPRISES PVT LTD is not responsible for the content or
                practices of any linked sites. Access to these sites is at your
                own risk.{" "}
              </p>
              <h4 className="tandh4">7. Limitation of Liability </h4>
              <p>
                To the fullest extent permitted by law, L V L GROUPS &
                ENTERPRISES PVT LTD shall not be liable for any damages arising
                out of or in connection with your use of the Website. This
                includes, without limitation, any direct, indirect, incidental,
                special, or consequential damages.
              </p>
              <h4 className="tandh4">8. Disclaimer of Warranties </h4>
              <p>
                The Website is provided “as is” without any representations or
                warranties, express or implied. L V L GROUPS & ENTERPRISES PVT
                LTD makes no representations or warranties in relation to the
                Website or the information and materials provided on the
                Website.{" "}
              </p>
              <h4 className="tandh4">9. Governing Law </h4>
              <p>
                These terms and conditions are governed by the laws of
                Australia. Any disputes will be subject to the exclusive
                jurisdiction of the courts of Australia.{" "}
              </p>
              <h4 className="tandh4">10. Changes to the Terms </h4>
              <p>
                L V L GROUPS & ENTERPRISES PVT LTD reserves the right to update
                these Terms and Conditions at any time without notice. Your
                continued use of the Website following any changes constitutes
                acceptance of those changes.{" "}
              </p>
              <h4 className="tandh4">11. Contact Information </h4>
              <p>
                If you have any questions about these Terms and Conditions,
                please contact us at <a href="mailto:admin@lvlgroups.com." className="email-txt" target="_blank" style={{color:"#3190dc"}}>
                  admin@lvlgroups.com.
                </a>{" "}.{" "}
              </p>
          
          </Col>
        </Row>
      </Container>
      {/* <Footer /> */}
    </>
  );
}

export default TandC;
