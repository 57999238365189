import React, { useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import "./WhyChoose.css";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import CareerContact from "../../pages/ContactUsForm/ContactUsForm";
import ContactUsForm from "../../pages/ContactUsForm/ContactUsForm";
import CustomModel from "../../compounds/Model/Model";
import { useNavigate } from "react-router-dom";
const WhyChoose = () => {
  const [showModel, setShowModel] = useState(false);
  const handleClose = () => setShowModel(false);
  const handleOpen = () => setShowModel(true);
  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the desired path
    navigate('/contact-us');
  };
  return (

    <>
      <Container fluid>
        <Row>
          <Col xs={12} lg={12} md={12} sm={12}>
            <h2 className="text-center whychooseus">Why Choose Us?</h2>
            <p className="whychoospp  m-2 text-center">
              <span className="parapara"> Strategic Customization:</span> We
              deeply understand your business's core, crafting IT solutions that
              seamlessly align with your vision and goals, ensuring a tailored
              approach every time.
            </p>
            <p className="whychoospp m-2 text-center">
              <span className="parapara"> Elite Expertise:</span> Our team
              comprises industry-leading professionals who bring a blend of
              creativity and technical prowess in web design, digital marketing,
              CRM integration, and IT consulting.
            </p>
            <p className="whychoospp m-2 text-center">
              <span className="parapara"> Innovative Edge:</span> Embrace the
              future with our commitment to leveraging the latest technological
              advancements, keeping your business ahead in the ever-evolving
              digital landscape.
            </p>
            <p className="whychoospp m-2 text-center">
              <span className="parapara"> Results-Driven Partnership:</span>{" "}
              We're not just service providers; we're your partners in success.
              Our focus is on delivering tangible outcomes that drive growth and
              elevate your business to new heights.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={12} md={12} sm={12}>
            <Row>
              <Col xs={12} sm={12} md={4} lg={4} className="mb-4"></Col>
              <Col
                xs={12}
                sm={12}
                md={4}
                lg={4}
                className="mb-4 ProductSoursebutton centered"
              >
                <Button
                  className="btn-p-src  "
                  variant="outline-light"
                  onClick={handleClick}
                >
                  {" "}
                 Contact Us <MdKeyboardDoubleArrowRight size={35} />
                </Button>
               
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} className="mb-4"></Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WhyChoose;
