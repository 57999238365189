import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import crm from "../../../Images/crmplatform-min.webp";
import planning from "../../../Images/planbninginter-min.webp";
import configcustom from "../../../Images/configsetup-min.webp";
import migration from "../../../Images/datamigration-min.webp";
import qltyassurance from "../../../Images/assurance-min.webp";
import training from "../../../Images/employing-team-min.webp";
import golive from "../../../Images/golive-min.webp";
import impoptimize from "../../../Images/optimizing-min.webp";
import Active from "../../../Images/evaluation-min.webp";
import ContactUsForm from "../../../pages/ContactUsForm/ContactUsForm";
import CustomModel from "../../../compounds/Model/Model";
import Buttons from "../../../compounds/Button/Button";
import NavBar from "../../../compounds/AppBar/AppBar";
import Footer from "../../../compounds/Footer/Footer";
import Aos from "aos";
import "aos/dist/aos.css";
import "./Crm.css";
import { useNavigate } from "react-router-dom";
const Crm = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the desired path
    navigate("/contact-us");
  };
  useEffect(() => {
    Aos.init();
  }, []);
  const [showModel, setShowModel] = useState(false);
  const handleClose = () => setShowModel(false);
  const handleOpen = () => setShowModel(true);
  return (
    <>
      {/* <NavBar /> */}
      {/* <Buttons /> */}

      <Container fluid className="crm container-fluid ">
        <Row className="mt-2">
          <Col xs={12} sm={12} md={12} lg={12} className=" headbg ">
            <h1 className=" text-center text-white mt-5">CRM INTEGRATION</h1>
            <br />
            <p className="text-center">
              Welcome to L V L GROUPS & ENTERPRISES PVT LTD, Expert CRM
              integration solutions for enhanced efficiency and customer
              engagement.
              <br />
              Our mission is to provide cutting-edge CRM integration services
              that optimize operations and improve customer relationships.
            </p>
            <p className="text-center"></p>
            {/* <h2 className="Webh2 text-center">
              Dominate the Digital Landscape with Unrivaled Web Design and
              Unstoppable Applications{" "}
            </h2> */}
            {/* </Col> */}
          </Col>
        </Row>
      </Container>
      <Container fluid className="mt-5">
        <Row className="mt-2">
          <Col xs={12} sm={12} md={12} lg={12}>
            <h2 className="crm text-center">
              {" "}
              Transform your business with seamless CRM integration – boost
              growth and efficiency today.
            </h2>

            <p>
              At L V L GROUPS & ENTERPRISES, we begin every CRM integration
              project with a comprehensive understanding of your specific
              business needs and objectives. Through an initial consultation, we
              collaborate directly with you and your stakeholders to delve into
              your company's operations, current technologies, and future goals.
              This phase is crucial for us to identify and document your
              requirements in detail, enabling us to provide a customized CRM
              solution that aligns perfectly with your business strategy.
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="mt-4 section-3colw ">
          <Col xs={12} sm={12} md={12} lg={12}>
            {/* <div className="is-stickeys"> */}
            <h2 className="Webh2 text-center m-5 ">
              Here’s a look at our CRM Integration:
            </h2>
            {/* </div> */}
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center mb-0 mt-2">
                  Selecting the Optimal CRM Platform
                </h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  Choosing the right CRM platform is key to successful
                  integration. Our expertise with Salesforce, HubSpot, Zoho, and
                  Microsoft Dynamics helps you select the best option based on
                  your industry, size, and budget, ensuring it meets current
                  needs and supports future growth.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img src={crm} alt="" loading="lazy" className="techweb image-with-shadow" />
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={planning}
                    alt="" loading="lazy"
                    className="techweb image-with-shadow "
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center mb-0 mt-2">
                  Detailed Integration Planning
                </h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  After choosing the CRM platform, we meticulously plan the
                  integration, detailing technical steps and creating a project
                  timeline. Our data mapping ensures a smooth transition,
                  preserving data integrity and minimizing disruptions for a
                  successful CRM implementation.
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center mb-0 mt-2">
                  Custom Configuration and Setup
                </h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  We customize your CRM to meet your business needs, including
                  user roles and workflow automations, and integrate it with key
                  third-party applications for a complete customer relationship
                  management solution.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={configcustom}
                    alt="" loading="lazy"
                    className="techweb image-with-shadow"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={migration}
                    alt="" loading="lazy"
                    className="techweb image-with-shadow "
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center mb-0 mt-2">
                  Comprehensive Data Migration
                </h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  We handle data migration with care, including cleansing to
                  ensure accuracy before transfer. A pilot migration tests for
                  issues, followed by a full migration to securely and
                  effectively transfer your data into the new CRM system.
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center mb-0 mt-2">
                  Thorough Testing and Quality Assurance
                </h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  Before the CRM goes live, we conduct thorough testing,
                  including integration and user acceptance tests, to ensure all
                  components work seamlessly. We address any issues promptly to
                  guarantee a robust and fully functional system at launch.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={qltyassurance}
                    alt="" loading="lazy"
                    className="techweb image-with-shadow"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={training}
                    alt="" loading="lazy"
                    className="techweb image-with-shadow "
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center mb-0 mt-2">
                  Training and Empowering Your Team
                </h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  We offer tailored training sessions for all roles, ensuring
                  proficiency in the new CRM. Along with comprehensive manuals,
                  we provide ongoing support to address any questions and help
                  your team maximize the CRM's value.
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center mb-0 mt-2">
                  Go-Live Support and System Monitoring
                </h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  When your CRM goes live, we ensure a smooth transition with
                  ongoing support and real-time monitoring. We adjust parameters
                  as needed and address any issues promptly to optimize
                  performance and minimize disruptions.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={golive}
                    alt="" loading="lazy"
                    className="techweb image-with-shadow"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={impoptimize}
                    alt="" loading="lazy"
                    className="techweb image-with-shadow "
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center mb-0 mt-2">
                  Continuous Improvement and Optimization
                </h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  Our support extends beyond go-live, with regular reviews to
                  ensure the CRM meets your evolving needs. We provide insights
                  on new features and enhancements to optimize your workflow and
                  ensure continued returns on your CRM investment.
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center mb-0 mt-2">
                  Active Feedback Loop and Service Enhancement
                </h2>
                <p className="pl-5 pr-5 pb-5 pt-3 mt-2">
                  We value your feedback and regularly engage with you to assess
                  satisfaction and gather insights. This ongoing dialogue helps
                  us refine our processes and services to ensure we meet your
                  expectations and provide superior CRM integration.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={Active}
                    alt="" loading="lazy"
                    className="techweb image-with-shadow "
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            {/* border-bottom: thick double #32a1ce; */}
            <div className="is-stickey">
              <h2 className="webdesign mt-3">Why Choose Us?</h2>
              <p>
                <span className="webdesign">Expertise Across Platforms:</span>{" "}
                Our deep expertise with major CRM platforms like Salesforce and
                HubSpot ensures tailored, industry-specific solutions for every
                client.
              </p>
              <p>
                <span className="webdesign">Customized Integration:</span> We
                customize every aspect of your CRM integration to align
                precisely with your business processes and goals.
              </p>
              <p>
                <span className="webdesign">Full-Service Support: </span>From
                initial setup to ongoing optimization, we provide comprehensive
                support to ensure your CRM system evolves with your needs.
              </p>
              <p>
                <span className="webdesign">Proactive Partnership:</span> We
                build long-term relationships, regularly reviewing your system
                and adapting our services to meet your changing needs.
              </p>
              <p>
                <span className="webdesign">Excellence in Delivery:</span> Our
                commitment to excellence means we aim to exceed your
                expectations, ensuring your CRM integration transforms your
                business operations.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row className="our-team-assistwebssubscribe">
          <Col xs={12} sm={12} md={8} lg={8}>
            <h2 className="text-center text-white p-3">
              Grow Your Business With L V L groups
            </h2>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} className="text-center">
            <Button
              className="btn btn-primary classifybtn mt-3"
              onClick={handleClick}
            >
              {" "}
              Contact Us
            </Button>
          </Col>
        </Row>
      </Container>

      {/* <Footer /> */}
    </>
  );
};

export default Crm;
