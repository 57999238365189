import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
// import Digital1 from "../../../Images/digital_marketing1.png";
import ContactUsForm from "../../../pages/ContactUsForm/ContactUsForm";
import CustomModel from "../../../compounds/Model/Model";
import Buttons from "../../../compounds/Button/Button";
import NavBar from "../../../compounds/AppBar/AppBar";
import Footer from "../../../compounds/Footer/Footer";
import influiance from "../../../Images/influiancemarketing-min.webp";
import digitalpr from "../../../Images/digitalpr-min.webp";
import seo from "../../../Images/seomarketing-min.webp";
import paid from "../../../Images/paidmarketing-min.webp";
import emailmar from "../../../Images/emailmarketing-min.webp";
import content from "../../../Images/contente-creation-min.webp";
import socialmedia from "../../../Images/socialmedia-min.webp";
import marketing from "../../../Images/marketing-stratagy-min.webp";
import Aos from "aos";
import "aos/dist/aos.css";
import "./Digital.css";
import { useNavigate } from "react-router-dom";
const Digital = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the desired path
    navigate("/contact-us");
  };
  // const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);
  const handleClose = () => setShowModel(false);
  const handleOpen = () => setShowModel(true);
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      {/* <NavBar /> */}
      {/* <Buttons /> */}
      <Container fluid className="digitalmed container-fluid ">
        <Row className="mt-2">
          <Col xs={12} sm={12} md={12} lg={12} className="headbg">
            <h1 className=" text-center text-white mt-5">Digital Marketing</h1>
            <br />
            <p className="text-center">
              Welcome to L V L GROUPS & ENTERPRISES PVT LTD, where we create
              powerful digital marketing strategies to elevate your brand and
              drive results.
              <br />
              Our mission is to empower businesses with innovative digital
              marketing solutions that boost brand visibility, engagement, and
              growth.
            </p>
            <p className="text-center"></p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="">
        <Row className="mt-2">
          <Col xs={12} sm={12} md={12} lg={12} className="mt-5">
            <h2 className="business text-center">
              Unleash Your Brand’s Potential with Our Cutting-Edge Digital
              Marketing Solutions
            </h2>
            <p className="">
              At L V L’S, we bring together an experienced team with a diverse
              range of skills to offer a full spectrum of digital marketing
              services. Our tailored approach is designed to maximize the return
              on investment for every campaign, ensuring our clients achieve
              their specific goals. In the ever-evolving digital landscape, our
              team stays on top of the latest trends across various
              specializations, allowing us to deliver exceptional results. With
              years of experience across multiple sectors, we apply the most
              effective digital marketing methods tailored to each client's
              industry and objectives. .
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-4 section-3colw ">
          <Col xs={12} sm={12} md={12} lg={12}>
            {/* <div className="is-stickeys"> */}
            <h2 className="Webh2 text-center m-5 ">
              Here’s a look at our Digital Marketing:
            </h2>
            {/* </div> */}
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center">
                  Social Media Marketing(SMM)
                </h2>
                <p className="pt-1 pb-2 pr-3 pl-3 mt-2">
                  L V L’S helps brands grow with tailored strategies that boost
                  ROI(Return On Investment). Our experts drive engagement, build
                  trust, and connect businesses with their target audiences in a
                  competitive market.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={socialmedia}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow mt-1"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={paid}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow mt-1"
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center">Paid Media</h2>
                <p className="pt-1 pb-2 pr-3 pl-3 mt-2">
                  L V L’S crafts expert strategies that drive traffic, boost
                  conversions, and maximize ROI. Serving clients globally, we
                  leverage targeted paid media like PPC(Pay Per Click) to ensure
                  instant visibility and strong customer relationships.
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center">Marketing Strategy</h2>
                <p className="pt-1 pb-2 pr-3 pl-3 mt-2">
                  L V L’S uses the E-Matrix™ methodology to deliver powerful
                  insights and drive growth for brands, from start-ups to
                  multinationals, ensuring success in complex markets.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={marketing}
                    alt="" 
                    loading="lazy"
                    className="techweb image-with-shadow mt-1"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={influiance}
                    alt="" loading="lazy"
                    className="techweb image-with-shadow mt-1"
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center">Influencer Marketing</h2>
                <p className="pt-1 pb-2 pr-3 pl-3 mt-2">
                  L V L’S excels in creating global strategies that connect
                  brands with key influencers. We combine creativity and
                  data-driven insights to craft impactful campaigns that enhance
                  brand visibility and deliver strong ROI.
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center">
                  Search Engine Optimization, (SEO)
                </h2>
                <p className="pt-1 pb-2 pr-3 pl-3 mt-2">
                  L V L’S offers a competitive edge with a pioneering approach
                  and passionate team. We tailor SEO campaigns to boost revenue
                  and brand authority, staying ahead of search algorithm changes
                  to ensure ongoing success for our global clients.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={seo}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow mt-1"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={content}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow mt-1"
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center">Content Creation</h2>
                <p className="pt-1 pb-2 pr-3 pl-3 mt-2">
                  L V L’S excels using the Opti Script model to produce
                  engaging, conversion-driven content. We specialize in
                  mobile-friendly video formats and impactful copy for effective
                  marketing across various platforms.
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center">Digital PR</h2>
                <p className="pt-1 pb-2 pr-3 pl-3 mt-2">
                  L V L’S delivers impactful campaigns with high-quality links
                  and media coverage. Our strategic, data-driven approach
                  enhances ROI and boosts your website’s authority and
                  relevance.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={digitalpr}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow mt-1"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="image-container">
                  <img
                    src={emailmar}
                    alt=""
                    loading="lazy"
                    className="techweb image-with-shadow mt-1"
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h2 className="Webh2 text-center">Email Marketing</h2>
                <p className="pt-1 pb-2 pr-3 pl-3 mt-2">
                  L V L’S excels in creating tailored strategies that drive
                  results. We leverage personalized communication and compelling
                  content to engage audiences, nurture leads, and boost business
                  growth locally and internationally.
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            {/* border-bottom: thick double #32a1ce; */}
            <div className="is-stickey">
              <h2 className="webdesign mt-3">Why Choose Us?</h2>
              <p>
                At L V L Groups, we stand out in the digital marketing landscape
                through our commitment to innovative strategies and deep client
                understanding. Here’s why we’re your ideal partner for achieving
                marketing excellence:
              </p>
              <p>
                <span className="webdesign">
                  Cutting-Edge Marketing Features:
                </span>{" "} <br />
                We use advanced analytics for market insights, AI-driven
                strategies for real-time adaptability, and custom automation to
                enhance efficiency and ROI.
              </p>
              <p>
                <span className="webdesign">
                  Deep Understanding of Client Expectations:
                </span>{" "}
                <br />
                We tailor strategies to your goals, collaborate closely with
                your team, and proactively address challenges to ensure
                successful outcomes.
              </p>
              <p>
                <span className="webdesign">
                  Effective Organic Content Strategies:{" "}
                </span>{" "}
                <br />
                We create engaging, SEO-optimized content that boosts visibility
                and positions your brand as a thought leader, building
                credibility and genuine connections with your audience.
              </p>
              <p>
                <span className="webdesign">
                  Integrated Traditional Marketing Strategies:
                </span>{" "}
                <br />
                We integrate traditional and digital marketing for consistent
                messaging, targeting specific demographics to expand your
                audience and boost engagement.
              </p>
              <p>
                <span className="webdesign">Proven Results:</span>
                <br /> Our
                successful campaigns and satisfied clients showcase our
                results-driven approach. We continually refine our strategies to
                ensure ongoing success and growth.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row className="our-team-assistwebssubscribe">
          <Col xs={12} sm={12} md={8} lg={8}>
            <h2 className="text-center text-white p-3">
              Grow Your Business With L V L groups
            </h2>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} className="text-center">
            <Button
              className="btn btn-primary classifybtn mt-3"
              onClick={handleClick}
            >
              {" "}
              Contact Us
            </Button>
          </Col>
        </Row>
      </Container>
      {/* <Footer /> */}
    </>
  );
};

export default Digital;
