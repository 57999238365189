import React, { useEffect } from "react";
import "./ItSourcing.css";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { Container, Row, Col, Card } from "react-bootstrap";
import SoftwareDevelopmentIcon from "../../Images/webdesign.webp";
import DigitalMarkating from "../../Images/business.webp";
import CyberSecurityIcon from "../../Images/management.webp";
import ItConsultingIcon from "../../Images/consultant_5467478.webp";
import crm from "../../Images/CRM-min.webp";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

function ProductSourcing() {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Container fluid className="Itsoursemain ">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="mt-3">
            <h2
              className="itstore"
              style={{ marginBottom: "3%", textAlign: "center" }}
            >
              Explore Our IT Services and Unleash Your Business Potential
            </h2>
            <p className="home-txt-container-sub-txt m-2">
              At L V L GROUPS & ENTERPRISES PVT LTD, we are dedicated to
              unlocking your business's full potential with our exceptional IT
              services. We offer a comprehensive suite of solutions, including
              innovative web design and application development to create a
              powerful online presence. Our digital marketing strategies are
              tailored to boost your brand’s visibility and drive engagement
              through targeted campaigns and SEO optimization. Additionally, our
              expert CRM integration services ensure that you streamline your
              customer relationships, improving efficiency and enhancing your
              sales processes. Our business and IT consulting services provide
              strategic insights and practical advice to help you navigate the
              complexities of today’s digital world and make informed decisions
            </p>
          </Col>
        </Row>

        <Row className="mt-4 ">
          <Col xs={12} sm={12} md={3} lg={3} className="mb-5 mt-3 itsoursecard">
            <a href="/webdesign&application" style={{ textDecoration: "none" }}>
              {" "}
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                  border: "4px solid #c6f1ff",
                  borderRadius: "1rem",
                  className: "aboutitcars ",
                  padding: "0.8rem",
                  background: " #3190dc ",
                  color: "#fff",
                }}
                className="aboutitcars"
              >
                <Card.Img
                  variant="top"
                  className=" imagewith-shadowsd"
                  src={SoftwareDevelopmentIcon}
                  alt="webicon"
                  style={{ width: "5rem", margin: "auto" }}
                />
                <Card.Body style={{ marginTop: "-3rem" }}>
                  <Card.Text>
                    <h6 style={{ fontWeight: "600" }}>
                      WebDesign & Application
                    </h6>
                    <p className="sourcp">
                      Empowering your business with cutting-edge technology. We
                      ensure long-term success and adaptability for businesses
                      of all sizes....
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3} className="mb-5 mt-3 itsoursecard">
            <a href="/digitalmedia" style={{ textDecoration: "none" }}>
              <Card
                style={{
                  width: "100%",
                  border: "4px solid #3190dc",
                  borderRadius: "1rem",
                  height: "100%",
                  className: "aboutitcars",
                  padding: "0.8rem",
                  background: " #c6f1ff ",
                  color: "#000",
                }}
                className="aboutitcars"
              >
                <Card.Img
                  variant="top"
                  className="imagewith-shadowsed"
                  src={DigitalMarkating}
                  alt="Digitalicon"
                  style={{ width: "5rem", margin: "auto", background: "none" }}
                />
                <Card.Body style={{ marginTop: "-3rem" }}>
                  <Card.Text>
                    <h6 style={{ fontWeight: "600" }}>Digital Marketing</h6>
                    <p className="sourcp">
                      Transforming Vision into Reality. Our expert team
                      translates your digital vision into a dynamic reality that
                      engages and captivates....
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3} className="mb-5 mt-3 itsoursecard">
            <a href="/crmintegration" style={{ textDecoration: "none" }}>
              {" "}
              <Card
                style={{
                  width: "100%",
                  border: "4px solid #c6f1ff",
                  borderRadius: "1rem",
                  height: "100%",
                  className: "aboutitcars",
                  padding: "0.8rem",
                  background: " #3190dc ",
                  color: "#fff",
                }}
                className="aboutitcars"
              >
                <Card.Img
                  variant="top"
                  className="imagewith-shadowsd"
                  src={CyberSecurityIcon}
                  alt="Crmicon"
                  style={{ width: "5rem", margin: "auto" }}
                />
                <Card.Body style={{ marginTop: "-3rem" }}>
                  <Card.Text>
                    <h6 style={{ fontWeight: "600" }}>CRM Integration</h6>
                    <p className="sourcp">
                      We specialize in tailoring CRM integration to fit your
                      unique business needs. Get a custom CRM solution designed
                      specifically for you...
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Col>

          <Col xs={12} sm={12} md={3} lg={3} className="mb-5 mt-3 itsoursecard">
            <a href="/Bisiness&itconsulting" style={{ textDecoration: "none" }}>
              {" "}
              <Card
                style={{
                  width: "100%",
                  border: "4px solid #3190dc",
                  borderRadius: "1rem",
                  height: "100%",
                  className: "aboutitcars",
                  padding: "0.8rem",
                  background: " #c6f1ff ",
                  color: "#000",
                }}
                className="aboutitcars"
              >
                <Card.Img
                  variant="top"
                  className="imagewith-shadowsed"
                  src={ItConsultingIcon}
                  alt="Businessicon"
                  style={{ width: "5rem", margin: "auto" }}
                />
                <Card.Body style={{ marginTop: "-3rem" }}>
                  <Card.Text>
                    <h6 style={{ fontWeight: "600" }}>
                      Business & IT Consulting
                    </h6>
                    <p className="sourcp">
                      Delivering innovative web solutions that perfectly blend
                      visual appeal with functionality. Experience seamless user
                      interactions with our cutting-edge designs. ....
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ProductSourcing;
