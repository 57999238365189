import React, { useEffect } from "react";

import NavBar from "../../../compounds/AppBar/AppBar";
import Footer from "../../../compounds/Footer/Footer";

import Aos from "aos";
import "aos/dist/aos.css";
import "./ClothingandApparel.css";
import Clothing from "./Clothing";
import Button from "../../../compounds/Button/Button";
function ClothingandApparel() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="clothing">
     
      <Clothing />
       </div>







  );
}

export default ClothingandApparel;
