import React from "react";
import NavBar from "../../compounds/AppBar/AppBar";
import Footer from "../../compounds/Footer/Footer";
import "./PolicyPrivacy.css";
import { Col, Container, Row } from "react-bootstrap";

function PolicyPrivacy() {
  return (
    <>
      {/* <NavBar /> */}
      <Container  className="privacy">
        <Row>
          <Col>
            <div className="tandc-text">
              <h1 className="privacyh1 text-center">Privacy Policy </h1>
              <h4 className="privacyh4">1. Introduction </h4>
              <p>
                Welcome to L V L GROUPS & ENTERPRISES PVT LTD. We are committed
                to protecting your personal information and your right to
                privacy. This Privacy Policy explains what information we
                collect, how we use it, and your rights in relation to it. By
                using our website{" "}
                <a href="https://lvlgroups.com/" target="_blank">
                  https://lvlgroups.com/
                </a>
                , you agree to the collection and use of information in
                accordance with this policy.{" "}
              </p>
              <h4 className="privacyh4"> Information We Collect </h4>
              <p>We collect the following types of information:</p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Personal Information:
                </span>{" "}
                This includes your name, email address, phone number, and any
                other information you voluntarily provide when filling out forms
                or communicating with us.{" "}
              </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Technical Information:
                </span>{" "}
                We may collect details about your device, IP address, browser
                type and version, time zone setting, and operating system as you
                navigate through our website
              </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Usage Data:
                </span>{" "}
                This includes information about how you use our website, such as
                the pages you visit, the time spent on those pages, and the
                links you click.{" "}
              </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Cookies and Tracking Technologies:
                </span>{" "}
                We may use cookies and similar tracking technologies to track
                activity on our website and store certain information.{" "}
              </p>
              <h4 className="privacyh4">3. How We Collect Your Information </h4>
              <p>We collect information in the following ways: </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Directly from You:
                </span>{" "}
                When you provide it to us through forms, surveys, or direct
                communication.{" "}
              </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Automatically:
                </span>{" "}
                As you interact with our website, we may collect information
                using cookies and other tracking technologies.
              </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  From Third Parties:
                </span>{" "}
                We may receive information from third-party services, such as
                analytics providers.{" "}
              </p>
              <h4 className="privacyh4">4. How We Use Your Information </h4>
              <p>We use the information we collect to: </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Provide and Improve Our Services:
                </span>{" "}
                To maintain and improve our website, including analyzing how our
                users interact with it.{" "}
              </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Communicate with You:
                </span>{" "}
                To respond to your inquiries, provide support, and send you
                updates, promotions, and other information with your consent.{" "}
              </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Ensure Legal Compliance:
                </span>{" "}
                To comply with legal obligations, such as record-keeping,
                reporting, and compliance with applicable laws.{" "}
              </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Personalize Your Experience:
                </span>{" "}
                To tailor content and offers to your interests and preferences.{" "}
              </p>

              <h4 className="privacyh4">5. Sharing Your Information </h4>
              <p>We may share your information with: </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Service Providers:
                </span>{" "}
                Third-party companies and individuals who help us operate our
                website, conduct our business, or provide services on our
                behalf.{" "}
              </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Legal Obligations:
                </span>{" "}
                We may disclose your information if required by law, or if we
                believe that such action is necessary to comply with legal
                obligations or protect the rights, property, or safety of L V L
                GROUPS & ENTERPRISES PVT LTD, our users, or others.{" "}
              </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Business Transfers:
                </span>{" "}
                If we are involved in a merger, acquisition, or sale of assets,
                your information may be transferred as part of that transaction.
              </p>

              <h4 className="privacyh4">6. Data Security </h4>
              <p>
                We implement a variety of security measures to maintain the
                safety of your personal information. However, no method of
                transmission over the Internet or electronic storage is 100%
                secure, so we cannot guarantee its absolute security.{" "}
              </p>

              <h4 className="privacyh4">7. Your Rights </h4>
              <p>
                Depending on your location, you may have the following rights
                regarding your personal data:{" "}
              </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Access:
                </span>{" "}
                The right to request copies of your personal data.{" "}
              </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Rectification:
                </span>{" "}
                The right to request correction of any inaccurate or incomplete
                data.{" "}
              </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Erasure:
                </span>{" "}
                The right to request deletion of your personal data under
                certain circumstances.{" "}
              </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Restrict Processing:
                </span>{" "}
                The right to request that we limit the processing of your
                personal data.{" "}
              </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Object to Processing:
                </span>{" "}
                The right to object to our processing of your personal data.{" "}
              </p>
              <p>
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Data Portability:
                </span>{" "}
                The right to request transfer of your data to another
                organization or to you.
              </p>
              <p>
                To exercise these rights, please contact us at{" "}
                <a
                  href="mailto:admin@lvlgroups.com."
                  target="_blank"
                  className="email-txt"
                  style={{ color: "#3190dc" }}
                >
                  admin@lvlgroups.com.
                </a>{" "}
              </p>
              <h4 className="privacyh4">8. Cookies </h4>
              <p>
                We use cookies to enhance your experience on our website. You
                can choose to accept or decline cookies. Most web browsers
                automatically accept cookies, but you can usually modify your
                browser settings to decline cookies if you prefer.
              </p>
              <h4 className="privacyh4">9. Changes to This Privacy Policy </h4>
              <p>
                We may update our Privacy Policy from time to time. Any changes
                will be posted on this page with an updated effective date. We
                encourage you to review this policy periodically for any
                changes.{" "}
              </p>

              <h4 className="privacyh4">10. Contact Us </h4>
              <p>
                If you have any questions about this Privacy Policy, please
                contact us at{" "}
                <a
                  href="mailto:admin@lvlgroups.com."
                  target="_blank"
                  className="email-txt"
                >
                  admin@lvlgroups.com.
                </a>{" "}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <Footer /> */}
    </>
  );
}

export default PolicyPrivacy;
