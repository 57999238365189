import React,{useState} from 'react'
import './Button.css'
import CustomModel from "../../compounds/Model/Model";
import GetStarted from '../../pages/GetStarted/GetStarted';
function Button() {
  const [showModel, setShowModel] = useState(false);
  const handleClose = () => setShowModel(false);
  const handleOpen = () => setShowModel(true);
  return (
    <div>
      <button className='btn btn-primary getstarted' onClick={handleOpen } >Join Us</button>
      
      <CustomModel
                  show={showModel}
                  handleClose={handleClose}
                  title="Join Us "
                  modalClassName="modeltop"
                  dialogClassName="custom-modal-width"
                  headerStyle={{ padding: "25px" }}
                  buttonStyle={{
                    position: "absolute",
                    top: "15px",
                    right: "15px",
                    padding: "0.8rem",
                  }}
                >
                  <GetStarted />
                </CustomModel>
    
    </div>
  )
}

export default Button
