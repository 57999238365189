import React from "react";
import NavBar from "../../../compounds/AppBar/AppBar";
import Footer from "../../../compounds/Footer/Footer";
import "aos/dist/aos.css";
import "./Technology.css";
import Technologys from "./Technologys";
import Button from "../../../compounds/Button/Button";
function Technology() {
  return (
    <div className="Technologies">
     
      <Technologys />
         </div>
  );
}

export default Technology;
