import React, { useEffect ,useState} from "react";
import { Col, Container, Row ,Button} from "react-bootstrap";
import ContactUsForm from "../../../pages/ContactUsForm/ContactUsForm";
import CustomModel from "../../../compounds/Model/Model";
import { IoIosArrowForward } from "react-icons/io";
import circle3 from "../../../Images/Mask-group-1.webp";
import circle1 from "../../../Images/Mask-group-2.webp";
import circle2 from "../../../Images/Mask-group-3.webp";
import circle4 from "../../../Images/Mask-group.webp";
import VideoPlayer from "../../../assets/toys zone.mp4";
import toysbg from "../../../Images/toys-background-copy-space.webp"
import "./Toy.css";
import Aos from "aos";
import "aos/dist/aos.css";

const Toy = () => {
  const [showModel, setShowModel] = useState(false);
  const handleClose = () => setShowModel(false);
  const handleOpen = () => setShowModel(true);
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Container fluid className="Toys-section p-0">
        <Row>
          <Col>
          <img
              src="{toysbg}"
              alt=""
              loading="lazy"
              className="toystext mt-5"
              srcset={`${toysbg} 300w, ${toysbg} 768w, ${toysbg} 1280w`}
              sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
            />
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col  xs={12} sm={12} md={12} lg={12} className="mt-5">
            <h1 className="text-center Toy">Toys</h1>
            <p className="text-center">
              Welcome to L V L GROUPS & ENTERPRISES PVT LTD, Discover toys that
              inspire joy, curiosity, and endless adventures.
            </p>
            <p className="text-center">
              Our mission is to inspire children's curiosity and creativity by providing
              safe, high-quality toys that foster learning and joy.
            </p>
            <h2 className="text-center toyh2">
              Breaking boundaries, setting standards—our toys redefine what it
              means to play.
            </h2>
            <br />
            <h4 className="text-center  titlestoys" id="title">
              Our Services
            </h4>

            <p>
              We excel in sourcing top-quality toys from around the globe. Our
              dedicated team ensures a seamless procurement process, from market
              analysis and supplier negotiations to stringent quality control,
              guaranteeing you receive the best products at competitive prices.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col  xs={12} sm={12} md={5} lg={5} className="">
            <div className="image-containercloth">
              <video
                autoPlay
                muted
                loop
                className="video image-with-shadowcloth"
              >
                <source src={VideoPlayer} type="video/mp4" />
                {/* Your browser does not support the video tag. */}
              </video>
            </div>
          </Col>
          <Col  xs={12} sm={12} md={7} lg={7} className="mt-3 ">
            <Row>
              <Col >
                <h4 className="text-center  titlestoys" id="title2">
                  Our Process
                </h4>

                <p>
                  We follow a meticulous process to ensure seamless sourcing,
                  from understanding your needs to delivering high-quality toys.
                </p>
              </Col>
              <Row>
                <Col  xs={12} sm={12} md={6} lg={6}>
                  <h4 className="toyh4">
                    <img
                      src={circle1}
                      alt=""
                      loading="lazy"
                      style={{ width: "auto", height: "25px" }}
                    />{" "}
                    Need Assessments:{" "}
                  </h4>
                  <p className="description-text">
                    Thorough understanding of your specific requirements.
                  </p>
                  <h4 className="toyh4">
                    <img
                      src={circle2}
                      alt=""
                      loading="lazy"
                      style={{ width: "auto", height: "25px" }}
                    />{" "}
                    Supplier Selection:{" "}
                  </h4>
                  <p className="description-text">
                    Identifying the best suppliers based on quality and
                    reliability.
                  </p>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <h4 className="toyh4">
                    <img
                      src={circle3}
                      alt=""
                      loading="lazy"
                      style={{ width: "auto", height: "25px" }}
                    />{" "}
                    Quotation & Negotiation:{" "}
                  </h4>
                  <p className="description-text">
                    Securing the best terms and prices through expert
                    negotiation
                  </p>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
      <br />
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <h4 className="text-center  titlestoys" id="title1">
              Supplier Network
            </h4>

            <p>
              Our extensive network of trusted global suppliers allows us to
              source a diverse range of high-quality toys, ensuring reliability
              and competitive pricing.
            </p>

            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h4 className="toyh4">
                  <img
                    src={circle1}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Quality and Ethics:{" "}
                </h4> 
                <p className="description-text">
                  Carefully vetted suppliers for quality and ethical practices
                </p>
                <h4 className="toyh4">
                  <img
                    src={circle2}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Diverse Selection:{" "}
                </h4>
                <p className="description-text">
                  Access to a wide variety of toys to meet diverse market
                  demands.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h4 className="toyh4">
                  <img
                    src={circle3}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Timely Delivery:{" "}
                </h4>
                <p className="description-text">
                  Strong relationships ensuring timely and efficient delivery
                </p>
              </Col>
            </Row>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6}>
            <h4 className="text-center  titlestoys" id="title1">
              Quality Assurance
            </h4>

            <p>
              We prioritize quality at every stage, implementing stringent
              control measures to ensure all toys meet international safety
              standards and exceed customer expectations.
            </p>

            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h4 className="toyh4">
                  <img
                    src={circle1}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Rigorous Inspections:{" "}
                </h4>
                <p className="description-text">
                  Comprehensive inspections at multiple stages of production.
                </p>
                <h4 className="toyh4">
                  <img
                    src={circle2}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Compliance:{" "}
                </h4>
                <p className="description-text">
                  Adherence to international safety and quality standards.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h4 className="toyh4">
                  <img
                    src={circle3}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Customer Satisfaction:{" "}
                </h4>
                <p className="description-text">
                  Focus on delivering durable, safe, and high-quality toys
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <div className="our-team-assisttoy">
          <h2 className="business text-center">Why Choose Us?</h2>
          <p className="">
            <img
              src={circle1}
              alt=""
              loading="lazy"
              style={{ width: "auto", height: "30px" }}
            />{" "}
            Experience the joy of play with our meticulously curated collection
            of toys, designed to inspire creativity, imagination, and endless
            fun for children of all ages.
          </p>
          <p className="">
            <img
              src={circle2}
              alt=""
              loading="lazy"
              style={{ width: "auto", height: "30px" }}
            />{" "}
            Trust in our commitment to safety and quality, ensuring every toy
            meets the highest standards for durability and child-friendly
            materials, giving you peace of mind.
          </p>
          <p className="">
            <img
              src={circle3}
              alt=""
              loading="lazy"
              style={{ width: "auto", height: "30px" }}
            />{" "}
            Discover innovative and unique toys that stand out from the rest,
            carefully selected to provide educational value and developmental
            benefits, making playtime both fun and enriching.
          </p>
        </div>
      </Container>
      <Container fluid>
        <Row className="our-team-assistwebssubscribe">
          <Col xs={12} sm={12} md={9} lg={9}>
            <h2 className="text-center text-white p-2 mt-3">
              Grow Your Business With L V L groups
            </h2>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3} className="text-center">
          <Button
                  className="hero-button btn btn-light mt-2" s
                  variant="light"
                  onClick={handleOpen}
                  
                >
                  Request Quotation <IoIosArrowForward className="arrow-icon" />
                </Button>
                <CustomModel
                  show={showModel}
                  handleClose={handleClose}
                  title="Request Quotation"
                  modalClassName="modeltop"
                  dialogClassName="custom-modal-width"
                  headerStyle={{ padding: "25px" }}
                  buttonStyle={{
                    position: "absolute",
                    top: "15px",
                    right: "15px",
                    padding: "0.8rem",
                  }}
                >
                  <ContactUsForm />
                </CustomModel>
            
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Toy;
