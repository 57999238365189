import React, { useEffect, useState } from "react";
import ContactUsForm from "../../../pages/ContactUsForm/ContactUsForm";
import CustomModel from "../../../compounds/Model/Model";
import { IoIosArrowForward } from "react-icons/io";
import circle3 from "../../../Images/Mask-group-1.webp";
import circle1 from "../../../Images/Mask-group-2.webp";
import circle2 from "../../../Images/Mask-group-3.webp";
import circle4 from "../../../Images/Mask-group.webp";
import VideoPlayer from "../../../assets/customized clothing.mp4";
import cloathingbg from "../../../Images/cloth.webp";
import Aos from "aos";
import "aos/dist/aos.css";
import "./Cloathing.css";
import { Col, Container, Row, Button } from "react-bootstrap";

const Clothing = () => {
  const [showModel, setShowModel] = useState(false);
  const handleClose = () => setShowModel(false);
  const handleOpen = () => setShowModel(true);
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Container fluid className="Clothing-section mt-5 p-0">
        <Row className="">
          <Col>
            <img
              src="{cloathingbg}"
              alt=""
              loading="lazy"
              className="clothtext mt-5"
              srcset={`${cloathingbg} 300w, ${cloathingbg} 768w, ${cloathingbg} 1280w`}
              sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
            />
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="mt-5">
            {/* <div className="our-team-assistclot"> */}
            <h1 className="text-center clothing">Clothing and Apparel</h1>
            <p className="text-center">
              Welcome to L V L GROUPS & ENTERPRISES PVT LTD, where elegance
              meets innovation. Discover luxurious, sustainable fabrics that
              redefine your style.
            </p>
            <p className="text-center">
              Our mission is to transform clothing and textile sourcing with top
              quality, outstanding service, and innovative solutions.
            </p>
            <h2 className="text-center clothh2">
              Textiles are our playground quality is our game.
            </h2>
            <br />
            {/* </div> */}
            <h4 className="text-center  titlescloths" id="title">
              Our Services
            </h4>

            <p>
              We provide top-tier clothing and textile sourcing tailored to your
              specific needs. Our comprehensive service includes supplier
              selection, quality assurance, and timely delivery, ensuring you
              receive the best products at competitive prices. Let us streamline
              your sourcing process so you can focus on growing your business.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5} className="">
            <div className="image-containercloth mt-5">
              <video
                autoPlay
                muted
                loop
                className="video image-with-shadowcloth"
              >
                <source src={VideoPlayer} type="video/mp4" />
                {/* Your browser does not support the video tag. */}
              </video>
            </div>
          </Col>

          <Col xs={12} sm={12} md={7} lg={7}className="sectioncloth ">
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <h4 className="text-center  titlescloths" id="title1">
                  Our Process
                </h4>

                <p>
                  Our streamlined process ensures efficient and effective
                  sourcing, tailored to meet your specific needs. We handle
                  everything from initial assessments to final negotiations,
                  guaranteeing a seamless experience and the best possible
                  outcomes.
                </p>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <h4 className="clothh4">
                      <img
                        src={circle1}
                        alt=""
                        loading="lazy"
                        style={{ width: "auto", height: "25px" }}
                      />{" "}
                      Need Assessments:{" "}
                    </h4>
                    <p className="description-text">
                      Detailed understanding of your requirements.
                    </p>
                    <h4 className="clothh4">
                      <img
                        src={circle2}
                        alt="" loading="lazy"
                        style={{ width: "auto", height: "25px" }}
                      />{" "}
                      Supplier Selection:{" "}
                    </h4>
                    <p className="description-text">
                      Careful evaluation and selection of the best suppliers.
                    </p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <h4 className="clothh4">
                      <img
                        src={circle3}
                        alt="" loading="lazy"
                        style={{ width: "auto", height: "25px" }}
                      />{" "}
                      Quotation & Negotiation:{" "}
                    </h4>
                    <p className="description-text">
                      Securing competitive pricing and favorable terms.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <br />
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <h4 className="text-center  titlescloths" id="title1">
              Supplier Network
            </h4>

            <p>
              Our extensive global supplier network ensures access to a diverse
              range of high-quality clothing and textiles. We meticulously vet
              each supplier to maintain the highest standards of quality and
              ethical practices.
            </p>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h4 className="clothh4">
                  <img
                    src={circle1}
                    alt="" loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Global Reach:{" "}
                </h4>
                <p className="description-text">
                  Partnerships with reliable suppliers worldwide.
                </p>
                <h4 className="clothh4">
                  <img
                    src={circle2}
                    alt="" loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Quality Compliance:{" "}
                </h4>
                <p className="description-text">
                  Adherence to international quality and ethical standards.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h4 className="clothh4">
                  <img
                    src={circle3}
                    alt="" loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Diverse Options:{" "}
                </h4>
                <p className="description-text">
                  Wide variety of materials and finished products.
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <h4 className="text-center  titlescloths" id="title1">
              Quality Assurance
            </h4>

            <p>
              We prioritize quality at every step, implementing stringent
              controls to ensure you receive only the best clothing and
              textiles. Our rigorous inspections and testing guarantee products
              that meet the highest standards of durability and safety.
            </p>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h4 className="clothh4">
                  <img
                    src={circle1}
                    alt="" loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Rigorous Inspections:{" "}
                </h4>
                <p className="description-text">
                  Thorough checks at every production stage.
                </p>
                <h4 className="clothh4">
                  <img
                    src={circle2}
                    alt="" loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Consistent Standards:{" "}
                </h4>
                <p className="description-text">
                  Adherence to top international quality benchmarks.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h4 className="clothh4">
                  <img
                    src={circle3}
                    alt="" loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Supplier Collaboration:{" "}
                </h4>
                <p className="description-text">
                  Continuous improvement and issue resolution with suppliers.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <div className="our-team-assistbusiness">
          <h2 className="business text-center ">Why Choose Us?</h2>
          <p className="">
            <img
              src={circle1}
              alt="" loading="lazy"
              style={{ width: "auto", height: "30px" }}
            />{" "}
            Blending traditional craftsmanship with modern technology, we ensure
            each piece strikes the perfect balance of style and durability
            through our extensive industry experience.
          </p>
          <p className="">
            <img
              src={circle2}
              alt="" loading="lazy"
              style={{ width: "auto", height: "30px" }}
            />{" "}
            Committed to sustainability, we use eco-friendly materials and
            sustainable production methods, promoting a greener future without
            compromising on quality.
          </p>
          <p className="">
            <img
              src={circle3}
              alt="" loading="lazy"
              style={{ width: "auto", height: "30px" }}
            />{" "}
            Offering tailored solutions for every client, we cater to specific
            needs from bespoke designs to large-scale production, guaranteeing
            satisfaction and excellence in every project.
          </p>
        </div>
      </Container>
      <Container fluid>
        <Row className="our-team-assistwebssubscribe">
          <Col xs={12} sm={12} md={9} lg={9}>
            <h2 className="text-center text-white p-2 mt-3">
              Grow Your Business With L V L groups
            </h2>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3} className="text-center">
            <Button
              className="hero-button btn btn-light mt-2"
              s
              variant="light"
              onClick={handleOpen}
            >
              Request Quotation <IoIosArrowForward className="arrow-icon" />
            </Button>
            <CustomModel
              show={showModel}
              handleClose={handleClose}
              title="Request Quotation"
              modalClassName="modeltop"
              dialogClassName="custom-modal-width"
              headerStyle={{ padding: "25px" }}
              buttonStyle={{
                position: "absolute",
                top: "15px",
                right: "15px",
                padding: "0.8rem",
              }}
            >
              <ContactUsForm />
            </CustomModel>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Clothing;
