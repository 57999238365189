import React, { useState } from "react";
import CareerContact from "../Careers/Career-Contact/CareerContact";
import NavBar from "../../compounds/AppBar/AppBar";
import Footer from "../../compounds/Footer/Footer";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
const SeoJd = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {/* <NavBar /> */}
      <Container fluid className="Seojd">
        <Container className="mt-5 p-0">
          <Row className="mt-5  p-4">
            <Col className="mt-5">
              <h3 className=" mt-5"> SEO Specialist</h3>
              <br />
              <p>
                {" "}
                <span
                  className="seospan"
                  style={{ fontSize: "22px", fontWeight: "500" }}
                >
                  {" "}
                  Position:
                </span>{" "}
                SEO Specialist
              </p>
              <p>
                {" "}
                <span
                  className="seospan"
                  style={{ fontSize: "22px", fontWeight: "500" }}
                >
                  {" "}
                  Experiance:
                </span>{" "}
                0 - 3 Years{" "}
              </p>
              <p>
                {" "}
                <span
                  className="seospan"
                  style={{ fontSize: "22px", fontWeight: "500" }}
                >
                  {" "}
                  Location:
                </span>{" "}
                Hyderabad(HYD){" "}
              </p>
              <p>
                {" "}
                <span
                  className="seospan"
                  style={{ fontSize: "22px", fontWeight: "500" }}
                >
                  {" "}
                  Type:
                </span>{" "}
                Full-Time
              </p>
              <br />
              <h3>Job Overview:</h3>
              <p>
                {" "}
                LVL Enterprises is seeking a proactive and experienced SEO
                Specialist to enhance our online presence and drive organic
                traffic to our website. The ideal candidate will have a deep
                understanding of SEO strategies and a passion for improving
                search engine rankings.
              </p>

              <h3>Key Responsibilities:</h3>

              <p>
                Conduct thorough keyword research and develop SEO strategies to
                improve organic search visibility.
              </p>
              <p>
                Implement on-page and off-page SEO techniques, including
                optimizing content, meta tags, and link building.
              </p>
              <p>
                Use SEO tools like Google Analytics, SEMrush, and Ahrefs to
                monitor, analyze, and report on website performance.
              </p>
              <p>
                Collaborate with content creators and web developers to ensure
                SEO best practices are integrated into website design and
                content.
              </p>
              <p>
                Stay informed about the latest SEO trends, algorithm updates,
                and industry changes.
              </p>
              <p>
                Provide actionable insights and recommendations based on data
                analysis to drive continuous improvement.
              </p>

              <h3>Qualifications:</h3>

              <p>
                Proven experience in SEO, with a strong track record of
                improving search engine rankings and driving traffic.
              </p>
              <p>
                Proficiency in SEO tools and analytics platforms (e.g., Google
                Analytics, Google Search Console).
              </p>
              <p>
                Solid understanding of search engine algorithms and ranking
                factors.
              </p>
              <p>Excellent analytical skills and attention to detail.</p>
              <p>Strong written and verbal communication skills.</p>
              <p>
                Bachelor’s degree in Marketing, Business, or a related field is
                preferred.
              </p>

              <a
                onClick={handleShow}
                // target="_blank"
                style={{
                  fontWeight: "700",
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#0e4cae",
                }}
              >
                <Button className="btnjob btn-primary"> Apply Here.</Button>{" "}
              </a>

              <br />

              <br />
            </Col>
          </Row>
        </Container>
        <Container>
          <Modal show={show} onHide={handleClose} centered className="modeltop">
            <Modal.Header style={{ padding: "25px" }}>
              {/* <Modal.Title>selected jd</Modal.Title> */}
              <Button
                variant="close"
                onClick={handleClose}
                style={{
                  position: "absolute",
                  top: "15px",
                  right: "15px",
                  padding: "0.8rem",
                }}
              ></Button>
            </Modal.Header>
            <div className="col-12 p-3 ">
              <CareerContact />
            </div>
            {/* <Modal.Footer> */}
            {/* <Button className="btn btn-primary" onClick={handleClose}>
          Close
        </Button> */}
            {/* </Modal.Footer> */}
          </Modal>
        </Container>
      </Container>
      {/* <Footer className="p-0" /> */}
    </>
  );
};

export default SeoJd;
