import React, { useEffect, useState } from "react";
import Aos from "aos";

import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import check from "../../Images/check-boxblue.webp";
import "aos/dist/aos.css";
import "./Hero2Section.css";
import { Container, Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import CustomModel from "../../compounds/Model/Model";
import ContactUsForm from "../../pages/ContactUsForm/ContactUsForm";
function Hero2section() {
  const [showModel, setShowModel] = useState(false);
  const handleClose = () => setShowModel(false);
  const handleOpen = () => setShowModel(true);

  const navigate = useNavigate();
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Container>
        <div className="container2">
          <div className="text-container2">
            <h2 className="text-center">We Will Help You In 4 Steps</h2>
          </div>
        </div>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="customAccordionHeader">
              What happens after I receive a product quote?{" "}
            </Accordion.Header>
            <Accordion.Body>
              <div className="txt">
                <img
                  src={check}
                  alt=""
                  loading="lazy"
                  style={{ width: "auto", height: "30px" }}
                />
                {/* data-aos="slide-left" data-aos-delay="500" data-aos-duration="500" */}
                <p className="steps">
                  Your assigned agent will have product quote with a 7 day turn
                  around time. Once the quote is accepted, a sample will be sent
                  to you.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              What services do you offer related to product sourcing and quality
              control?
            </Accordion.Header>
            <Accordion.Body>
              <div className="txt">
                <img
                  src={check}
                  alt=""
                  loading="lazy"
                  style={{ width: "auto", height: "30px" }}
                />
                {/* data-aos="slide-left" data-aos-delay="600" data-aos-duration="500" */}
                <p className="steps">
                  We manage everything to do with Manufacturing, Price
                  Negotiation, samples, Prototypes and Quality Control.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              What should I do if I have a specific need or enquiry and want to
              contact customer support?
            </Accordion.Header>
            <Accordion.Body>
              <div className="txt">
                <img
                  src={check}
                  alt=""
                  loading="lazy"
                  style={{ width: "auto", height: "30px" }}
                />
                {/*data-aos="slide-left" data-aos-delay="500" data-aos-duration="500" */}
                <p className="steps">
                  Submit your enquiry by telling us what you need.Our costumer
                  support will contact you within a few hours assigning with our
                  team or agent to get you detailed information.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>
              What steps are involved once a product is selected for production,
              and how is the importing process managed?
            </Accordion.Header>
            <Accordion.Body>
              <div className="txt">
                <img
                  src={check}
                  alt=""
                  loading="lazy"
                  style={{ width: "auto", height: "30px" }}
                />
                {/* data-aos="slide-left" data-aos-delay="500" data-aos-duration="500" */}
                <p className="steps">
                  Once the perfect product has been chosen, we will start
                  production and we will handle entire importing process for you
                  including freight forwarding, preparing all documentation and
                  getting your products straight to your nominated location.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={4} lg={4} className="mb-4"></Col>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="mb-4 ProductSoursebutton"
          >
            <button
              className="btn-p-src btn "
              variant="outline-light"
              onClick={handleOpen}
            >
              Contact Us <MdKeyboardDoubleArrowRight size={35} />
            </button>
            <CustomModel
              show={showModel}
              handleClose={handleClose}
              title="Contact Us"
              modalClassName="modeltop"
              dialogClassName="custom-modal-width"
              headerStyle={{ padding: "25px" }}
              buttonStyle={{
                position: "absolute",
                top: "15px",
                right: "15px",
                padding: "0.8rem",
              }}
            >
              <ContactUsForm />
            </CustomModel>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} className="mb-4"></Col>
        </Row>
      </Container>
    </>
  );
}

export default Hero2section;
