import React from "react";
import Footer from "../Footer/Footer";
import Button from "../Button/Button";
import Navbar from "../AppBar/AppBar";
import NotFound from "./NotFound";


const Error = () => {
  return (
    <div>
     
      {/* <Button /> */}
       <NotFound />
     
    </div>
  );
};

export default Error;
