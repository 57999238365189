import React, { useState } from "react";
import CareerContact from "../Careers/Career-Contact/CareerContact";
import NavBar from "../../compounds/AppBar/AppBar";
import Footer from "../../compounds/Footer/Footer";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
const DigitalmarketingJd = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {" "}
      {/* <NavBar /> */}
      <Container fluid className="digital ">
        <Container className="mt-5 p-0">
          <Row className="mt-5 p-4">
            <Col className="mt-5">
              <h3 className=" mt-5"> Digital Marketing Specialist</h3>
              <br />
              <p>
                {" "}
                <span className="seospan"> Position:</span> Digital Marketing
                Specialist
              </p>
              <p>
                {" "}
                <span className="seospan"> Experiance:</span> 0 - 3 Years{" "}
              </p>
              <p>
                {" "}
                <span className="seospan"> Location:</span> Hyderabad(HYD){" "}
              </p>
              <p>
                {" "}
                <span className="seospan"> Type:</span> Full-Time
              </p>
              <br />
              <h3>Job Overview:</h3>
              <p>
                {" "}
                LVL Enterprises is seeking a dynamic Digital Marketing
                Specialist to drive our online marketing efforts and elevate our
                brand presence. The ideal candidate will be experienced in
                managing digital marketing campaigns and leveraging various
                channels to achieve our marketing objectives.
              </p>

              <h3>Key Responsibilities:</h3>

              <p>
                Develop and execute digital marketing campaigns across multiple
                platforms, including social media, email, and PPC.
              </p>
              <p>
                Create compelling content for digital channels, including blog
                posts, social media updates, and email newsletters.
              </p>
              <p>
                Monitor and analyze campaign performance using tools such as
                Google Analytics, Facebook Ads Manager, and other relevant
                platforms.
              </p>
              <p>
                Manage social media accounts and engage with our audience to
                build brand loyalty and drive traffic.
              </p>
              <p>
                Collaborate with the design team to create marketing materials
                and visuals.
              </p>
              <p>
                Stay informed about digital marketing trends and technologies to
                keep campaigns innovative and effective.
              </p>

              <h3>Qualifications:</h3>

              <p>
                Proven experience as a Digital Marketing Specialist or similar
                role with a successful track record.
              </p>
              <p>
                Proficiency in digital marketing tools and platforms (e.g.,
                Google Ads, Facebook Ads, HubSpot).
              </p>
              <p>
                Strong knowledge of SEO, SEM, and social media marketing
                strategies.
              </p>
              <p>
                Excellent communication skills and the ability to create
                engaging content.
              </p>
              <p>
                Analytical skills to interpret data and optimize campaign
                performance.
              </p>
              <p>
                Bachelor’s degree in Marketing, Business, or a related field is
                preferred.
              </p>

              <a
                onClick={handleShow}
                // target="_blank"
                style={{
                  fontWeight: "700",
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#0e4cae",
                }}
              >
                <Button className="btnjob btn-primary"> Apply Here.</Button>{" "}
              </a>

              <br />

              <br />
            </Col>
          </Row>
        </Container>
        <Container>
          <Modal show={show} onHide={handleClose} centered className="modeltop">
            <Modal.Header style={{ padding: "25px" }}>
              {/* <Modal.Title>selected jd</Modal.Title> */}
              <Button
                variant="close"
                onClick={handleClose}
                style={{
                  position: "absolute",
                  top: "15px",
                  right: "15px",
                  padding: "0.8rem",
                }}
              ></Button>
            </Modal.Header>
            <div className="col-12 p-3 ">
              <CareerContact />
            </div>
            {/* <Modal.Footer> */}
            {/* <Button className="btn btn-primary" onClick={handleClose}>
            Close
          </Button> */}
            {/* </Modal.Footer> */}
          </Modal>
        </Container>
      </Container>
      {/* <Footer className="p-0" /> */}
    </>
  );
};

export default DigitalmarketingJd;
