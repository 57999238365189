import React, { useEffect } from "react";
import "./ProductSourcingContainerHome.css";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Toys from "../../Images/storage-box_4287373.webp";
import Package from "../../Images/production.1.webp";
import cloathing from "../../Images/sport-wear.webp";
import rawmaterial from "../../Images/supply.webp";
// import prod from "../../Images/Productimg.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaTruck, FaBox, FaTshirt, FaPaw } from "react-icons/fa";
// import gifgloab from "../../Images/Vector.webp";
import 'bootstrap/dist/css/bootstrap.min.css';
function ProductSourcing() {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Container fluid className="Productsource ">
        <Col xs={12} sm={12} md={12} lg={12}className="mb-4 ">
          <h2
            className="product"
            style={{
              marginBottom: "1%",

              textAlign: "center",
            }}
          >
            Discover the Premium Product Sourcing Solutions
          </h2>
        </Col>

        <Row class>
          <Col xs={12} sm={12} md={6} lg={6}className="mb-4  mt-5 ">
            <p className="para m-2">
              At L V L GROUPS & ENTERPRISES PVT LTD, Our agents work with a
              well-curated selection of reputable manufacturers and suppliers
              who are guaranteed to satisfy your requirements.
            </p>

            <p className="para m-2">
              We specialize in premium product sourcing, bringing you the
              highest quality products across various categories. From raw
              materials crucial for manufacturing to toys that spark joy, we
              ensure that every product meets stringent quality standards. Our
              expertise extends to sourcing office and packaging supplies that
              support smooth operations and efficiency in any business
              environment. Additionally, we curate a diverse range of clothing
              and apparel, combining fashion with functionality to meet the
              demands of today’s consumers.
            </p>
            <p className="para m-2">
              Our approach is centered around understanding your unique needs
              and delivering solutions that are tailored to your business. We
              leverage our extensive network of reliable suppliers and our deep
              market knowledge to provide you with the best products at
              competitive prices. Whether you’re looking for sustainable raw
              materials, innovative toys, practical office supplies, or stylish
              apparel, we’ve got you covered. Partner with us for a seamless,
              efficient, and stress-free sourcing experience that adds value to
              your business.
            </p>
          </Col>
          {/* <Col md={1} lg={1}></Col> */}
          <Col xs={12} sm={12} md={6} lg={6} className="mb-4 mt-5">
            <Row className="blockinmobile">
              <Col xs={12} sm={12} md={6} lg={6}  className="mb-5 mt-3  itsoursecard">
                <a href="/rawmaterial" style={{ textDecoration: "none" }}>
                  {" "}
                  <Card
                    style={{
                      width: "100%",
                      border: "4px solid #3190dc",
                      borderRadius: "1rem",
                      height: "100%",
                      background: " #c6f1ff ",
                      color: "#000",
                    }}
                    className="aboutitcars"
                  >
                    <Card.Img
                      variant="top"
                      className=" imagewith-shadows  rawmater"
                      src={rawmaterial}
                      alt="rawmaterialicon"
                      loading="lazy"
                      style={{ width: "5rem", margin: "auto" }}
                    />
                    <Card.Body style={{ marginTop: "-4rem" }}>
                      <Card.Text>
                        <h6 style={{ fontWeight: "600" }}>Raw Materials</h6>
                        <p className="prod">
                          Our streamlined supply chain guarantees the right raw
                          materials are delivered exactly when you need them,
                          ensuring reliability and timeliness in every step of
                          the process. ....
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="mb-5 mt-3 itsoursecards">
                <a href="/ofc&pkg" style={{ textDecoration: "none" }}>
                  {" "}
                  <Card
                    style={{
                      width: "100%",
                      border: "4px solid #c6f1ff",
                      borderRadius: "1rem",
                      height: "100%",
                      background: " #3190dc ",
                      color: "#fff",
                      marginTop: "-3rem",
                    }}
                    className="aboutitcarss "
                  >
                    <Card.Img
                      variant="top"
                      className=" imagewith-shadow office" 
                      src={Package}
                      alt="office-package-icon"
                      loading="lazy"
                      style={{ width: "5rem", margin: "auto" }}
                    />
                    <Card.Body style={{ marginTop: "-3rem" }}>
                      <Card.Text>
                        <h6 style={{ fontWeight: "600" }}>
                          Office/Package Supplies
                        </h6>
                        <p className="prod">
                          From premium office essentials to robust packaging
                          solutions, we ensure unmatched efficiency and
                          reliability, delivered directly to your workspace....
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            </Row>{" "}
            <Row className="blockinmobile">
              <Col xs={12} sm={12} md={6} lg={6}  className="mb-5 mt-3 itsoursecard">
                <a href="/clothing&apparel" style={{ textDecoration: "none" }}>
                  {" "}
                  <Card
                    style={{
                      width: "100%",
                      border: "4px solid #c6f1ff",
                      borderRadius: "1rem",
                      height: "100%",
                      background: " #3190dc ",

                      color: "#fff",
                    }}
                    className="aboutitcars"
                  >
                    <Card.Img
                      variant="top"
                      className=" imagewith-shadow"
                      src={cloathing}
                      alt="clothingicon"
                      loading="lazy"
                      style={{ width: "5rem", margin: "auto" }}
                    />
                    <Card.Body style={{ marginTop: "-3rem" }}>
                      <Card.Text>
                        <h6 style={{ fontWeight: "600" }}>Clothing & Appreal</h6>
                        <p className="prod">
                          Quality fabrics for quality creations. Choose our
                          top-notch textiles to ensure your creations are always
                          exceptional....
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="mb-5 mt-3 itsoursecards">
                <a href="/toys" style={{ textDecoration: "none" }}>
                  {" "}
                  <Card
                    style={{
                      width: "100%",
                      border: "4px solid #3190dc",
                      borderRadius: "1rem",
                      height: "100%",
                      background: " #c6f1ff ",
                      color: "#000",
                      marginTop: "-3rem",
                    }}
                    className="aboutitcarss"
                  >
                    <Card.Img
                      variant="top"
                      className=" imagewith-shadows"
                      src={Toys} loading="lazy"
                      alt="Toysicon"
                      style={{ width: "5rem", margin: "auto" }}
                    />
                    <Card.Body style={{ marginTop: "-4rem" }} >
                      <Card.Text>
                        <h6 style={{ fontWeight: "600" }}>Toys</h6>
                        <p className="prod">
                          Committed to excellence in every toy we supply. Our
                          dedication ensures you receive only the best toys
                          available. toys ....
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ProductSourcing;
