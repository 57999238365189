import { Container, Row, Col ,Button} from "react-bootstrap";
import React, { useEffect,useState } from "react";
import circle3 from "../../../Images/Mask-group-1.webp";
import circle1 from "../../../Images/Mask-group-2.webp";
import circle2 from "../../../Images/Mask-group-3.webp";
import ContactUsForm from "../../../pages/ContactUsForm/ContactUsForm";
import CustomModel from "../../../compounds/Model/Model";
import { IoIosArrowForward } from "react-icons/io";
import "./RaMaterial.css";
import VideoPlayer from "../../../assets/RAW MATERIAL LVL.mp4";
import rawmaterialbg from "../../../Images/raw-bg-1.webp"
import Aos from "aos";
import "aos/dist/aos.css";
const RaMaterial = () => {
  const [showModel, setShowModel] = useState(false);
  const handleClose = () => setShowModel(false);
  const handleOpen = () => setShowModel(true);
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Container fluid className="Raw-section p-0">
        <Row>
          <Col >
          <img
              src="{rawmaterialbg}"
              alt=""
              loading="lazy"
              className="rawmaerialtext mt-5"
              srcset={`${rawmaterialbg} 300w, ${rawmaterialbg} 768w, ${rawmaterialbg} 1280w`}
              sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
            />
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}className="mt-5">
            <h1 className="text-center raw">Raw Material</h1>
            <p className="text-center">
              Welcome to L V L GROUPS & ENTERPRISES PVT LTD, We source
              high-quality raw materials tailored to your needs.
            </p>
            <p className="text-center">
              Our mission is to provide top-quality sourcing solutions with
              unmatched efficiency and reliability, empowering businesses to
              thrive and innovate.
            </p>
            <h2 className="rawh2 text-center">
              Empower your business with top-tier raw material sourcing – where
              quality meets reliability for your success
            </h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="mt-3 ">
            <br />
            <div id="wrapper">
              <h4 className="text-center  titlesrawmateri" id="title">
                Our Services
              </h4>

              <p>
                We offer comprehensive product sourcing solutions, ensuring
                high-quality raw materials at competitive prices. Our global
                network of reliable suppliers guarantees a steady supply chain.
                From need assessment to final delivery, we provide a seamless,
                transparent, and efficient experience, allowing you to focus on
                your core business activities.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5} className="">
            <div className="image-containercloth">
              <video
                autoPlay
                muted
                loop
                className="video image-with-shadowcloth"
              >
                <source src={VideoPlayer} type="video/mp4" />
                {/* Your browser does not support the video tag. */}
              </video>
            </div>
          </Col>
          <Col xs={12} sm={12} md={7} lg={7} className="mt-3 ">
            <div className="wrapper1">
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <div id="wrapper">
                    <h4 className="text-center  titlesrawmateri" id="title2">
                      Our Process
                    </h4>
                    <p>
                      We follow a systematic approach to ensure you receive the
                      best raw materials, from understanding your needs to
                      securing favorable terms and timely delivery.
                    </p> 
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <h4 className="rewh4">
                    <img
                      src={circle1}
                      alt=""
                      loading="lazy"
                      style={{ width: "auto", height: "25px" }}
                    />{" "}
                    Need Assessments:{" "}
                  </h4>
                  <p className="description-text">
                    Collaborate closely to understand and identify your specific
                    requirements.
                  </p>
                  <h4 className="rewh4">
                    <img
                      src={circle2}
                      alt=""
                      loading="lazy"
                      style={{ width: "auto", height: "25px" }}
                    />{" "}
                    Supplier Selection:{" "}
                  </h4>
                  <p className="description-text">
                    Leverage our network to shortlist and evaluate the best
                    suppliers.
                  </p>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <h4 className="rewh4">
                    <img
                      src={circle3}
                      alt=""
                      loading="lazy"
                      style={{ width: "auto", height: "25px" }}
                    />{" "}
                    Quotation & Negotiation:{" "}
                  </h4>
                  <p className="description-text">
                    Obtain and compare detailed quotations, negotiating the best
                    terms for you.
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <br></br>
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <h4 className="text-center  titlesrawmateri" id="title1">
              Supplier Network
            </h4>
            <p>
              Our extensive supplier network spans multiple industries, ensuring
              access to diverse, high-quality raw materials from reputable
              partners.
            </p>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h4 className="rewh4">
                  <img
                    src={circle1}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Global Reach:
                </h4>
                <p className="description-text">
                  Access to a wide range of materials from various industries
                  and locations.
                </p>
                <h4 className="rewh4">
                  <img
                    src={circle2}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Strong Relationships:{" "}
                </h4>
                <p className="description-text">
                  Partnerships with reliable suppliers adhering to strict
                  quality and ethical standards.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h4 className="rewh4">
                  <img
                    src={circle3}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Customized Sourcing:
                </h4>
                <p className="description-text">
                  Ability to source both common and specialized raw materials
                  tailored to your specific needs.
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div id="wrapper">
              <h4 className="text-center  titlesrawmateri" id="title1">
                Quality Assurance
              </h4>
              <p>
                We implement rigorous quality assurance processes to ensure that
                all sourced materials meet your exact specifications and
                industry standards.
              </p>
            </div>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h4 className="rewh4">
                  <img
                    src={circle1}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Thorough Inspections:
                </h4>
                <p className="description-text">
                  Conduct detailed inspections and testing at various supply
                  chain stages.
                </p>
                <h4 className="rewh4">
                  <img
                    src={circle2}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Supplier Monitoring:{" "}
                </h4>
                <p className="description-text">
                  Continuously evaluate suppliers to maintain high-quality and
                  reliability.
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} >
                <h4 className="rewh4">
                  <img
                    src={circle3}
                    alt=""
                    loading="lazy"
                    style={{ width: "auto", height: "25px" }}
                  />{" "}
                  Standards Compliance:{" "}
                </h4>
                <p className="description-text">
                  Ensure all materials meet stringent quality and industry
                  standards.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <div className="our-team-assistrawm">
          <h2 className="business text-center">Why Choose Us?</h2>
          <p className="">
            <img
              src={circle1}
              alt=""
              loading="lazy"
              style={{ width: "auto", height: "30px" }}
            />{" "}
            We guarantee top-quality raw materials through our extensive network
            of trusted suppliers, ensuring reliable and consistent deliveries.
          </p>
          <p className="">
            <img
              src={circle2}
              alt=""
              loading="lazy"
              style={{ width: "auto", height: "30px" }}
            />{" "}
            Our customized sourcing solutions are tailored to your unique
            business needs, providing competitive pricing and timely deliveries.
          </p>
          <p className="">
            <img
              src={circle3}
              alt=""
              loading="lazy"
              style={{ width: "auto", height: "30px" }}
            />{" "}
            Our dedicated customer support team is always available to assist
            you, offering prompt and effective solutions for a seamless sourcing
            experience.
          </p>
        </div>
      </Container>
      <Container fluid>
        <Row className="our-team-assistwebssubscribe">
          <Col xs={12} sm={12} md={9} lg={9}>
            <h2 className="text-center text-white p-2 mt-3">
              Grow Your Business With L V L groups
            </h2>
          </Col>
          <Col xs={12} sm={12} md={3} lg={3} className="text-center">
          <Button
                  className="hero-button btn btn-light mt-2" s
                  variant="light"
                  onClick={handleOpen}
                  
                >
                  Request Quotation <IoIosArrowForward className="arrow-icon" />
                </Button>
                <CustomModel
                  show={showModel}
                  handleClose={handleClose}
                  title="Request Quotation"
                  modalClassName="modeltop"
                  dialogClassName="custom-modal-width"
                  headerStyle={{ padding: "25px" }}
                  buttonStyle={{
                    position: "absolute",
                    top: "15px",
                    right: "15px",
                    padding: "0.8rem",
                  }}
                >
                  <ContactUsForm />
                </CustomModel>
            
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RaMaterial;
