import React from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const Model = ({
  show,
  handleClose,
  title,
  children,
  modalClassName,
  dialogClassName,
  headerStyle,
  buttonStyle,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={modalClassName}
      dialogClassName={dialogClassName}
    >
      <Modal.Header style={headerStyle}>
        <Button variant="close" onClick={handleClose} style={buttonStyle} />
        {title && <Modal.Title>{title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default Model;
