import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./About.css";
import Footer from "../../compounds/Footer/Footer";
import Button from "../../compounds/Button/Button";
import Navbar from "../../compounds/AppBar/AppBar";
// import About from "../../pages/About/About";
import webdesign from "../../Images/Web-Design-and-Application.webp";
import digitalmar from "../../Images/Digital-Marketing.webp";
import crmintegration from "../../Images/CRM-Integration.webp";
import businessit from "../../Images/Business-and-IT-Consulting.webp";
import Webimg1 from "../../Images/webside1.webp";
import Design from "../../Images/Designer.webp";
import Expertise from "../../Images/Expertise.webp";
import Branding from "../../Images/personal-branding.webp";
import WhyFromIndia from "../../Images/yfromindia.webp";
const About = () => {
  return (
    <>
      <Container fluid className="diigesfy ">
       
        {/* <Button /> */}
        <Container fluid className="webmed container-fluid ">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className=" headbg ">
              <h1 className=" text-center text-white mt-5">About Us </h1>
            </Col>
          </Row>
        </Container>

        <Container fluid className="mt-5">
          <Row>
            {/* <Col xs={12} sm={12} md={4} lg={4}>
            <img src={Webimg1} alt="" className="techweb image-with-shadow " />
          </Col> */}
            <Col xs={12} sm={12} md={12} lg={12}>
              <h2 className="text-center classifytext">
                Product Sourcing-Quality Products at Affordable Prices
              </h2>
              <p>
                Team up with us to save up to 30% on goods. We specialize in
                merchandise (export & import) and business consulting, helping
                you source quality products directly from manufacturers and
                suppliers in India. Our extensive knowledge and commitment
                ensure that all your sourcing needs are met efficiently. We work
                with a wide range of products, including office supplies,
                garments, agri-products, raw materials, electrical equipment,
                machinery, and more.
              </p>
            </Col>
          </Row>
        </Container>

        <section className="mt-5">
          <Container>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <h2 className="text-center classifytext m-3">
                  Product Services
                </h2>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12} sm={12} md={8} lg={8}>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <h4 className="text-center classifytext ">
                      We Can Make Your Life Easier
                    </h4>
                    <p className="p-2">
                      Sourcing products from abroad can be challenging, with
                      communication barriers often leading to unproductive
                      conversations. Finding reliable manufacturers is
                      difficult, as many appear competent but prove problematic
                      in practice.
                    </p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div className="image-container">
                      <img
                        src={Design}
                        alt=""
                        loading="lazy"
                        className="techweb image-with-shadow"
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div className="image-container">
                      <img
                        src={Expertise}
                        alt=""
                        className="techweb image-with-shadow "
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <h4 className="text-center classifytext ">
                      Use our expertise to automate inventory and streamline
                      deals efficiently.
                    </h4>
                    <p className="p-2">
                      We handle all communications with Indian manufacturers,
                      removing language barriers and travel needs. Provide your
                      requirements, and we'll find qualified vendors to deliver
                      quality products within your budget.
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <h4 className="text-center classifytext ">
                      Premium Goods at Affordable Prices
                    </h4>
                    <p className="p-2">
                      We provide premium packaging and private labeling to help
                      your business stand out. Partnering with trusted
                      manufacturers, we ensure quality products are delivered
                      directly to your warehouse as per your inventory cycle.
                    </p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div className="image-container">
                      <img
                        src={Branding}
                        alt=""
                        loading="lazy"
                        className="techweb image-with-shadow"
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div className="image-container ">
                      <img
                        src={WhyFromIndia}
                        alt=""
                        loading="lazy"
                        className="techweb image-with-shadow "
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} className="">
                    <h4 className="text-center classifytext">
                      Why Import from Asia–Pacific.
                    </h4>
                    <p className="p-2">
                      Overseas sourcing provides lower costs and high production
                      capabilities. We ensure quality products from India and
                      Australia, offering a smooth order-to-delivery process.
                      Contact us for more information.
                    </p>
                  </Col>
                </Row>
              </Col>
              
              <Col xs={12} sm={12} md={4} lg={4} className="">
                {/* border-bottom: thick double #32a1ce; */}
                <div className="is-stickey ">
                  <h2 className="classifytext mt-3">Why Choose Us?</h2>
                  <p>
                    <span className="webdesign"> Reliable Supply Chain:</span>{" "}
                    Our strong network ensures consistent availability of
                    high-quality materials and products, keeping your operations
                    on track without delays.
                  </p>
                  <p>
                    <span className="webdesign">Tailored Solutions:</span> Our
                    team We offer customized sourcing solutions to meet your
                    specific business needs, from essential supplies to
                    specialized products.
                  </p>
                  <p>
                    <span className="webdesign"> Quality Assurance: </span>Every
                    product undergoes rigorous quality checks to meet the
                    highest standards, ensuring reliability and satisfaction.
                  </p>
                  <p>
                    <span className="webdesign">
                      {" "}
                      Efficiency and Timeliness:
                    </span>{" "}
                    Our efficient processes and reliable logistics guarantee
                    prompt and smooth order fulfillment, keeping your business
                    running seamlessly.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="mt-5">
          <Container>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <h2 className="text-center classifytext m-3"> IT Services</h2>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12} sm={12} md={8} lg={8}>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div className="image-container">
                      <img
                        src={webdesign}
                        alt=""
                        loading="lazy"
                        className="techweb image-with-shadow"
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <h4 className="text-center classifytext ">
                      Web Design and Application
                    </h4>
                    <p className="p-3">
                      Exceptional web design is key to a strong online presence.
                      We create visually appealing, user-friendly websites
                      tailored to your brand, ensuring optimal performance
                      across all devices and driving customer engagement.
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <h4 className="text-center classifytext ">
                      Digital Marketing
                    </h4>
                    <p className="p-3">
                      Our digital marketing services enhance your online
                      presence, using targeted strategies in SEO, PPC, content
                      marketing, and social media to drive engagement and boost
                      conversions. We focus on delivering measurable results for
                      your business.
                    </p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div className="image-container">
                      <img
                        src={digitalmar}
                        alt=""
                        loading="lazy"
                        className="techweb image-with-shadow "
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div className="image-container">
                      <img
                        src={crmintegration}
                        alt=""
                        loading="lazy"
                        className="techweb image-with-shadow"
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <h4 className="text-center classifytext ">
                      CRM Integration
                    </h4>
                    <p className="p-3">
                      Our CRM integration services connect your CRM with key
                      business tools, streamlining operations and improving
                      efficiency. We customize solutions to fit your workflow,
                      automate tasks, and enhance customer relationships,
                      driving growth and success.
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <h4 className="text-center classifytext ">
                      Business and IT Consulting
                    </h4>
                    <p className="p-3">
                      Our IT consulting services provide strategic guidance and
                      tailored solutions to meet your technology needs,
                      enhancing efficiency, reducing costs, and driving growth.
                      We align your IT investments with business goals, helping
                      you stay ahead in a rapidly evolving landscape.
                    </p>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div className="image-container">
                      <img
                        src={businessit}
                        alt=""
                        loading="lazy"
                        className="techweb image-with-shadow "
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} className="mb-4">
                {/* border-bottom: thick double #32a1ce; */}
                <div className="is-stickey">
                  <h2 className="classifytext">Why Choose Us?</h2>
                  <p>
                    <span className="webdesign"> Comprehensive Services:</span>{" "}
                    They provide web design, digital marketing, CRM
                    integrations, and business/IT consulting, allowing you to
                    address all your needs in one place.
                  </p>
                  <p>
                    <span className="webdesign">Specialized Expertise:</span>{" "}
                    With their diverse range of services, LVL Groups brings
                    expert knowledge to each area, ensuring high-quality
                    results.
                  </p>
                  <p>
                    <span className="webdesign"> Holistic Approach: </span>Their
                    consulting helps align your business strategy with IT
                    infrastructure, improving efficiency and effectiveness.
                  </p>
                  <p>
                    <span className="webdesign"> Customization:</span> LVL
                    Groups tailors their services to fit your specific needs,
                    providing solutions that work best for your business.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <Footer /> */}
      </Container>
    </>
  );
};

export default About;
