import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import notfoundpage from "../../Images/404re-min.webp";
import "./NotFound.css";
const NotFound = () => {
  return (
    <Container fluid className=" Pagenot  ">
      <Row className="">
        <Col xs={12} sm={12} md={6} lg={6} className="text-left headsdd">
          <h1 className="headsddad text-center">Oops! Page Not Found</h1>
          <p>
            We couldn't find the page you were looking for. But don't worry,
            we're here to help!
          </p>
          <p className="text-left">
            Let's get you back on track: 
            <br />
            Return to <a href="/home" className="txtcolor">Home </a>
            <br />
            Explore our <a href="/services" className="txtcolor">Services </a>
            <br />
            Contact <a href="/contact" className="txtcolor">Support </a>
          </p>
          <p>
            If you need immediate assistance, please reach out to us at{" "}
            <a href="mailto:info@lvlgroups.com" className="email-txtcolor">
              info@lvlgroups.com
            </a>{" "}
            .
          </p>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <img src={notfoundpage} loading="lazy" alt="notfound" className="notfound" />
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
